
import { Dialog } from "../../components/Dialog";
import { useForm } from "react-hook-form";


function DlgConfirm(props: { libelle:any,onClose:any, onConfirm: any }) {

    const {  handleSubmit } = useForm();
    


    const onCloseDlg = async() => {
      
        props.onClose();
    }
    const onSubmit = (data: any) => {
        props.onConfirm();
    }

    return (
        <Dialog
            title="Confirmation"
           // open={openSetState}
            onClose={onCloseDlg}
            onSubmit={handleSubmit(onSubmit)}
            actionbutton={
                <div>
                    <input className="btn btn-outline-danger margright" type="submit" value="Valider" />
                    <div onClick={onCloseDlg} className="btn btn-outline-secondary" >Annuler</div>
                </div>
            }>
            <div className="dlg_delete">
            <div>{ props.libelle}</div>

            </div>



        </Dialog >
    );
}
export default DlgConfirm;

