import  { useEffect, useState } from 'react';
import { Pet } from '../../common/models/pet';
import { dataService } from '../../services/Services';
import DlgInfoComplementaireEdit from '../../common/dialog/DlgInfoComplementaireEdit';
import { NavLink } from 'react-router-dom';

export default function PetInfoComplementaire(props: { petId: number }) {
  const [petInfo, setPetInfo] = useState(new Pet())
  const [modeVue, setModeVue] = useState("display");

  useEffect(() => {
    loadData();

  }, [props.petId]);

  const loadData = async () => {
    dataService.Pets.getPet(props.petId).then((response) => { setPetInfo(response.data); });
  }
  const onCloseDlg = async () => {
    setModeVue("display");
  }

  const openDlgEdit = async () => {
    setModeVue("edit");
  }


  const onValid = async (pet: Pet) => {
    dataService.Pets.saveInfoCompl(pet).then((response) => { onCloseDlg(); loadData(); });
  }

  return (

    <div className="card b">
      <div className="card-content ">
        <div className="card-header">
          <h3>Information complémentaire</h3>
        </div>
        <div className="card-body">
          <div className="form-group row">
            <div className="col-md-12">De le part du propriétaire</div>
          </div>
          <div className="form-group row">
            <div className="col-md-12"><strong>{petInfo.InfoProprio}</strong></div>
          </div>
          <div className="form-group row">
            <div className="col-md-12">Constaté en pension</div>
          </div>
          <div className="form-group row">
            <div className="col-md-12"><strong>{petInfo.InfoPension} </strong></div>
          </div>

          <hr className="d-print-none" />
          <div className="card-action clearfix">
            <NavLink to={'/pet-compl-edit/' + props.petId}>
              <div className="btn btn-outline-primary">Modifier</div>
            </NavLink>
          </div>
        </div>
      </div>
      {modeVue === "edit" && <DlgInfoComplementaireEdit infoCompl={petInfo} onClose={onCloseDlg} onValid={onValid} />}
    </div>

  );
}