import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { dataService } from "../../services/Services";
import { Pet } from "../../common/models/pet";
import { useNavigate, useParams } from "react-router-dom";


export default function PetEdit(props: any) {

  let history = useNavigate();
  const params = useParams()

  const { reset, register, handleSubmit } = useForm();
  const [petInfo, setPetInfo] = useState<Pet>(new Pet());

  useEffect(() => {

    loadData();

  }, [params]);


  useEffect(() => {

    reset(petInfo);

  }, [petInfo]);



  const loadData = async () => {
    if (params.id !== undefined) {
      if (parseInt(params.id) > 0) {
        dataService.Pets.getPet(parseInt(params.id)).then((response) => { computePet(response.data) });
      }

    }
  }

  const computePet = async (pet: Pet) => {
    setPetInfo(pet);

  }


  const onSubmit = (data: any) => {
    var pet = data;
    pet.Id = petInfo.Id;
    dataService.Pets.saveInfoCompl(pet).then((response) => { history(-1); });
  }



  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="container-fluid margtop"  >
        <h4>Modification informations complémentaires</h4>
        <div className="">

          <div className="form-group ">
            <label >De la part du propriétaire</label>

            <textarea placeholder="Indication donnée par le propriétaire sur l'animal"
              className="form-control note-editor" rows={6} {...register("InfoProprio", { required: false })} />

          </div>
          <div className="form-group ">
            <label>Constaté en pension</label>

            <textarea placeholder="Indication sur l'animal constatée à la pension"
              className="form-control note-editor" rows={6} {...register("InfoPension", { required: false })} />

          </div>
          <div className="ligne">
            <div id="editEtatmessageError" className="text-danger"></div>
          </div>
        </div >
        <div className="d-flex justify-content-end margtop margbottom">
          <input className="btn btn-outline-primary margright" type="submit" value="Valider" />
          <div className="btn btn-outline-secondary margright" onClick={() => history(-1)} >Annuler</div>
        </div>
      </div>
    </form>
  );
}