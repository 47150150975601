import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { dataService } from "../../services/Services";
import { useNavigate, useParams } from "react-router-dom";
import { Pension } from "../../common/models/pension";
import FileResizer from "react-image-file-resizer";
import { getNoLogoImage } from "../../utils/Tools";


export default function ConfigPensionEdit(props: any) {

  let history = useNavigate();
  const params = useParams()

  const { reset, register, handleSubmit } = useForm();

  const [modeTarification, setModeTarification] = useState(0);

  const [pensionInfo, setPensionInfo] = useState<Pension>(new Pension());

  const [imgfile, uploadimg] = useState("");
  const [logoPrevious, setLogoPrevious] = useState("");
  const [logoChange, setLogoChange] = useState(false);

  useEffect(() => {

    loadData();

  }, [params]);


  useEffect(() => {

    reset(pensionInfo);

  }, [pensionInfo]);



  const loadData = async () => {
    dataService.Configuration.getInfoPension().then((response) => { computeInfo(response.data) });
  }

  const computeInfo = async (info: Pension) => {
    if (info.Logo === "" || info.Logo === null)
      info.Logo = getNoLogoImage();
    setLogoPrevious(info.Logo);
    setModeTarification(info.ModeTarification);
    setPensionInfo(info);
    setLogoChange(false);

  }

  const onChangeModeTarification = (event: any) => {
    setModeTarification(parseInt(event.target.value));
  }

  const onSubmit = async (data: any) => {
    var pension = data;
    pension.Id = pensionInfo.Id;
    pension.ModeTarification = modeTarification;
    var restsave = await dataService.Configuration.savePension(pension);
    if (logoChange)
      await dataService.Configuration.updateLogoPension(imgfile);
    history(-1);
  }
  const onDeleteLogo = (e: any) => {
    setLogoPrevious(getNoLogoImage());
    uploadimg("");
    setLogoChange(true);
  }

  const onLogoChange = (e: any) => {
    if (e.target.files.length !== 0) {

      var fileInput = false;
      if (e.target.files[0]) {
        fileInput = true;
      }
      if (fileInput) {
        try {
          FileResizer.imageFileResizer(
            e.target.files[0],
            300,
            300,
            "JPEG",
            100,
            0,
            (uri: any) => {
              setLogoChange(true);
              uploadimg(uri)
              setLogoPrevious(uri);
            },
            "base64"
          );
        } catch (err) {
          //          console.log(err);
        }
      }


    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="container-fluid margtop"  >
        <h4>Modification information sur la pension</h4>
        <div className="row">
          <div className="col-6">
            <div className="form-group ">
              <label >Nom</label>
              <input placeholder="Nom" type="text" className="form-control"   {...register("Nom", { required: false })} />
            </div>
            <div className="form-group ">
              <label >Adresse</label>
              <textarea placeholder="Adresse" className="form-control note-editor" rows={2}   {...register("Adresse", { required: false })} />
            </div>
          </div>
          <div className="col-6">
            <div className="d-flex">
              <img src={logoPrevious} style={{ height: "150px", maxWidth: "600px", marginRight: "10px" }} alt="" />
              <div>
                <div className="custom-file">
                  <input type="file" className="custom-file-input d-none" id="customFile" onChange={onLogoChange} />
                  <label className="custom-file-label btn btn-outline-primary" htmlFor="customFile">Choisir un logo</label>
                </div>
                <div className="btn btn-outline-danger margtop" onClick={onDeleteLogo} >Supprimer logo</div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group ">
          <div className="row">
            <div className="col-6">
              <label >Code postal</label>
              <input placeholder="Code postal" className="form-control" {...register("CodePostal", { required: false })} />
            </div>
            <div className="col-6">
              <label >Ville</label>
              <input placeholder="Ville" className="form-control "  {...register("Ville", { required: false })} />
            </div>
          </div>
        </div>
        <div className="form-group ">
          <div className="row">
            <div className="col-6">
              <label >Fixe</label>
              <input placeholder="Téléphone" type="text" className="form-control"  {...register("NumTel", { required: false })} />
            </div>
            <div className="col-6">
              <label >Portable</label>
              <input placeholder="Portable" type="text" className="form-control"  {...register("NumPortable", { required: false })} />
            </div>
          </div>
        </div>
        <div className="form-group ">
          <label >Email</label>
          <input placeholder="Email" type="text" className="form-control"  {...register("Email", { required: false })} />

        </div>
        <div className="form-group ">
          <label >Numéro Siret</label>
          <input placeholder="Numéro siret" type="text" className="form-control"  {...register("Siret", { required: false })} />

        </div>
        <div className="form-group ">
          <label >Représenté par</label>
          <input placeholder="Représenté par" type="text" className="form-control"  {...register("RepresentePar", { required: false })} />

        </div>
        <div className="form-group ">
          <label >Mentions légales facture</label>
          <textarea placeholder="Mentions légales" className="form-control  note-editor" rows={4}  {...register("MentionsLegales", { required: false })} />

        </div>
        <div className="form-group ">
          <label >Mentions légales contrat</label>
          <textarea placeholder="Mentions légales du contrat" className="form-control  note-editor" rows={4}   {...register("MentionsLegalesContrat", { required: false })} />

        </div>
        <div className="form-group ">
          <label >Alerte vaccin (jours avant)</label>
          <input placeholder="Nombre de jours avant" type="number" className="form-control"  {...register("VaccinAlert", { required: false })} />

        </div>
        <div className="form-group ">
          <label >Poucentage d'acompte</label>
          <input placeholder="Poucentage acompte" type="number" step="0.01" className="form-control"  {...register("PercentAcompte", { required: false })} />

        </div>
        <div className="form-group ">
          <div className="row">
            <div className="col-6">
              <label >Mode de tarification</label>
              <select className="form-control" {...register("ModeTarification", { required: false, onChange: (e) => { onChangeModeTarification(e) } })}>
                <option value="1" >Nuit</option>
                <option value="2" >Journée et demi journée</option>
                <option value="3" >Journée</option>
              </select>
            </div>

            {modeTarification === 2 && <div className="col-6">
              <label >Heure fin matinée</label>
              <select className="form-control"  {...register("HeureFinMatin", { required: false })} >
                <option value="0" >Minuit</option>
                <option value="1" >1H</option>
                <option value="2" >2H</option>
                <option value="3" >3H</option>
                <option value="4" >4H</option>
                <option value="5" >5H</option>
                <option value="6" >6H</option>
                <option value="7" >7H</option>
                <option value="8" >8H</option>
                <option value="9" >9H</option>
                <option value="10" >10H</option>
                <option value="11" >11H</option>
                <option value="12" >12H</option>
                <option value="13"  >13H</option>
                <option value="14" >14H</option>
                <option value="15" >15H</option>
                <option value="16" >16H</option>
                <option value="17" >17H</option>
                <option value="18" >18H</option>
                <option value="19" >19H</option>
                <option value="20" >20H</option>
                <option value="21" >21H</option>
                <option value="22" >22H</option>
                <option value="23" >23H</option>
              </select>

            </div>}
          </div>
        </div >
        <div className="d-flex justify-content-end margtop margbottom">
          <input className="btn btn-outline-primary margright" type="submit" value="Valider" />
          <div className="btn btn-outline-secondary margright" onClick={() => history(-1)} >Annuler</div>
        </div>
      </div>
    </form>
  );
}