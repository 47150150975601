import { useState, useEffect } from "react";
import { NavLink,   } from 'react-router-dom'
import { dataService } from "../../services/Services";
import { getDateStr2FromTimeStamp, getDateTimeStrFromTimeStamp, getTimeStrFromTimeStamp } from "../../utils/Tools";
import { PlanningEvent } from "../../common/models/planning-event";

function  Events( props:any ) {
    const [events, setEvents] = useState<PlanningEvent[]>([]);
    useEffect(() => {
     
        dataService.Periodes.listEventDay(props.selectedDate).then((response) => { setEvents(response.data) });

    }, [props.selectedDate]);

    return (
        <div className="card b">
            <div className="card-content ">
                <div className="card-header">
                    <h3>Evènements</h3>
                </div>
                <div className="card-body limit250">

                    {events !== undefined && events.map((item) => {
                        let sameDate = getDateStr2FromTimeStamp(item.DateDeb)===getDateStr2FromTimeStamp(item.DateFin);
                        return(
                            <div key={item.Id} className='d-flex margbottom justify-content-between line'>
                                <div className='d-flex'>    
                                    <div className="icon-event ">
                                        {item.TypeEvent ===1 && <i className="fa-solid fa-calendar-check margleft margright text-secondary" ></i>}
                                        {item.TypeEvent ===2 && <i className="fa-solid fa-shop-lock margleft margright text-red" ></i>}
                                    </div>
                                    <div style={{padding: '5px'}}>
                                        <div className="text-bold"> {item.Description}</div>

                                        {sameDate && <div>
                                            <span> de </span>
                                            <span>
                                                {getTimeStrFromTimeStamp(item.DateDeb)}
                                            </span>
                                            <span> à </span>
                                            <span>
                                                {getTimeStrFromTimeStamp(item.DateFin)}
                                            </span>
                                        </div>
                                        }
                                        {!sameDate && <div>
                                            <span> du </span>
                                            <span>
                                                {getDateTimeStrFromTimeStamp(item.DateDeb)}
                                            </span>
                                            <span> au </span>
                                            <span>
                                                {getDateTimeStrFromTimeStamp(item.DateFin)}
                                            </span>
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div>
                                </div>
                            </div>)
                        })}
                    {(events === undefined || events.length === 0) && <div > Aucun évènement</div>
                    }
                </div>
            </div>
        </div>);

};
export default Events;
