import { useState, useEffect, useContext } from "react";
import { Link,  Outlet, useLocation } from "react-router-dom";
import { ConfigContextType } from "../common/models/config";
import { Page } from "../common/models/page";
import { ConfigPension } from "../common/models/pension";
import { ConfigContext } from "../context/ConfigContext";
import { dataService } from "../services/Services";


const MasterPage = () => {
  const [listPage, setListPage] = useState<Page[]>([]);
  const { config,savePage,saveModeTarification,saveExistFacture,savePensionId } = useContext(ConfigContext) as ConfigContextType;
  const location=useLocation()

  useEffect(() => {

    dataService.Configuration.getPages().then((response) => { updateListPage(response.data)});
    dataService.Configuration.getConfigPension().then((response) => { updateConfigPension(response.data)});

  }, []);


  const updateListPage = async (listPage: Page[]) => {
    savePage(listPage);
    saveExistFacture( listPage.findIndex(x=>x.Url==='invoice')>0);
    setListPage(listPage)
  }
  
  const updateConfigPension = async (configPension: ConfigPension) => {
    saveModeTarification(configPension.ModeTarification);
    savePensionId(configPension.PensionId);
    
  }

  return (
    <div>
     {config.ListPage.length>0 && <div>
    
      <nav className="navbar navbar-light justify-content-between icon_planimalin">
        <a className="navbar-brand" href="https://planimalin.fr" target="_blank" rel="noreferrer">
          <div className="brand-logo">
            <img
              className="iconePln"
              src="/img/planimalin_RGB_short.svg"
              alt="App Logo"
            />
          </div>
        </a>
        {/* <a className="navbar-brand" href="https://backup.planimalin.fr" target="_blank" rel="noreferrer">
          <div style={{fontSize:'15px'}}>
           Si problème ancienne version dispo ici  <i className="fa-solid fa-bullseye"></i>
          </div>
        </a> */}
      </nav>
      <div className="d-flex menu">
        <div className="leftMenu">
          <div className="sidebar">
            <ul className="sidebar-nav">
              {
                listPage != null &&
                listPage.map((page, index) => {
                  return <li key={index}>
                       <Link to={"/" + page.Url} className={location.pathname.includes(page.Url)?'sidebar_active':''}> <img
                        alt={page.Nom}
                        src={
                          "/img/" + page.NomImage.replace("png", "svg")
                        }
                      /></Link>
                   
                  </li>
                })}
              <li>
                <a href="/login" title="déconnexion">
                  <img
                    alt="déconnexion"
                    src="/img/planimalin_out2.svg"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="rightContent">
          <Outlet />
        </div>
      </div>
    </div>}
    </div>
  );
};
export default MasterPage;
