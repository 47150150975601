import React from 'react';
import {  NavLink, useParams } from 'react-router-dom';
import { BoxPeriode } from '../../common/models/box-periode';
import { InfoDayCalendar } from '../../common/models/info-day-calendar';
import { MinMaxDate } from '../../common/models/min-max-date';
import { Periode } from '../../common/models/periode';
import { PlanningEvent } from '../../common/models/planning-event';
import Planning from './Planning';
import PlanningEventItem from './PlanningEventItem';
import PlanningItem from './PlanningItem';

export default function PlanningOther(props: { listEvent:PlanningEvent[],heightLineEvent:number,infoMinMaxDate: MinMaxDate, listDay: InfoDayCalendar[], listWeekend: InfoDayCalendar[],openEditEvent:any }) {
   
    const params = useParams()
    const widthColBox =200;
    React.useEffect(() => {

    }, [params]);


    const openDlgEdit = async () => {
        let newEvent = new PlanningEvent();
      
        newEvent.Id= 0;
        newEvent.TypeEvent= 1;  
        newEvent.Description= "";  
        var day=new Date();
    
        if(props.infoMinMaxDate.MinDate>(day.getTime()/1000))
          day = new Date(props.infoMinMaxDate.MinDate*1000);
          if(props.infoMinMaxDate.MaxDate<(day.getTime()/1000))
          day = new Date(props.infoMinMaxDate.MaxDate*1000);
    
          newEvent.DateDeb = (new Date(day.getFullYear(),day.getMonth(),day.getDate(),8,0,0)).getTime()/1000;    
          newEvent.DateFin = (new Date(day.getFullYear(),day.getMonth(),day.getDate(),18,0,0)).getTime()/1000;
        props.openEditEvent(newEvent);
    }
    const openDlgEditDay = async (infoJour: InfoDayCalendar) => {
        let newEvent = new PlanningEvent();
        newEvent.Id= 0;
        newEvent.TypeEvent= 1;  
        newEvent.Description= "";  
        newEvent.DateDeb = (new Date(infoJour.Year,infoJour.Month,infoJour.Jour,8,0,0)).getTime()/1000;    
        newEvent.DateFin = (new Date(infoJour.Year,infoJour.Month,infoJour.Jour,18,0,0)).getTime()/1000;
  
        props.openEditEvent(newEvent);
    }


    return (
        <div style={{ height: props.heightLineEvent + 'px', display: 'flex',backgroundColor:'#C3E2DF' }}  >
            {/* nom du box  */}
            <div className="firstColonne d-flex justify-content-between" style={{ height: props.heightLineEvent + 'px', lineHeight: props.heightLineEvent + 'px', maxWidth: widthColBox + 'px', minWidth: widthColBox + 'px' }} >
                <div>Mon planning</div>
                <div className="btn btn-primary btn-action" onClick={openDlgEdit} >+</div>
            </div>
            {/* calendrier box */}
            <div className="calendarDay">
                <div style={{ height: props.heightLineEvent + 'px', width: '100%', position: 'relative' }}
                    className="line_day">

                    {/* info week end et autre  */}
                    {props.listWeekend && props.listWeekend.map((infoWeekend,index) => (
                        <div key={index} className={`special_day ${infoWeekend.ClassDay}`}
                            style={{ height: (props.heightLineEvent - 1) + 'px', width: infoWeekend.Width + 'px', left: infoWeekend.Posleft + 'px', top: '0px' }}>
                        </div>
                    ))}

                    {/* grille jour du mois */}
                    <div style={{ display: 'flex' }}>
                        {props.listDay && props.listDay.map((infoJour,index) => (
                            
                            <div key={index}
                                onClick={(event) => openDlgEditDay(infoJour)}
                                className={`cell_day pointer ${infoJour.Jour === 1 ? 'firstDay' : ''}`}
                                style={{ width: infoJour.Width + 'px', height: props.heightLineEvent + 'px' }}></div>))}

                    </div>
                    {/*  events */}
                    {props.listEvent && props.listEvent.map((eventInfo) => (
                        <PlanningEventItem eventInfo={eventInfo} key={eventInfo.Id} onEditEvent={props.openEditEvent} />
                    ))}

                </div >
            </div >
        </div >
    );

}
