import { useContext } from "react";
import { ConfigContext } from "../../context/ConfigContext";
import { ConfigContextType } from "../models/config";
import { Dialog } from "../../components/Dialog";
import { useForm } from "react-hook-form";
import { Tarification } from "../models/tarification";


export default function DlgTarifEdit(props: { tarif: Tarification, onClose: any, onValid: any }) {


    const { config } = useContext(ConfigContext) as ConfigContextType;
    const { register,  handleSubmit } = useForm();


    const onSubmit = (data: any) => {

        var tarif = data;
        tarif.Id = props.tarif.Id;
        tarif.Categorie = props.tarif.Categorie;
        props.onValid(tarif);
    }

    const onCloseDlg = async () => {

        props.onClose();
    }

    return (
        <Dialog
            title="Information sur le tarif"
            onClose={onCloseDlg}
            onSubmit={handleSubmit(onSubmit)}
            actionbutton={
                <div>
                    <input className="btn btn-outline-primary margright" type="submit" value="Valider" />
                    <div onClick={onCloseDlg} className="btn btn-outline-secondary" >Annuler</div>
                </div>
            }>
            <div className="dlg_tarif_edit">
                <div className="form-group row ">
                    {console.log(config)}
                    <label className="col-4 col-form-label">Libelle du tarif</label>
                    <div className="col-8">
                        <input placeholder="Libelle" type="text" className="form-control" {...register("Libelle", { required: false })} defaultValue={props.tarif.Libelle} />
                    </div>
                </div>
                <div className="form-group row">
                    {config.ModeTarification === 1 && <label className="col-4 col-form-label" >Prix nuit</label>}
                    {(config.ModeTarification === 2 || config.ModeTarification === 3 )&& <label className="col-4 col-form-label">Prix journée</label>}
                    <div className="col-8">
                        <input placeholder="Montant journée" type="number" step="0.01" className="form-control" {...register("Montant", { required: false })} defaultValue={props.tarif.Montant} />
                    </div>
                </div>
                {config.ModeTarification === 2 && <div className="form-group row">
                    <label className="col-4 col-form-label">Prix demi-journée</label>
                    <div className="col-8">
                        <input placeholder="Montant demi journée" type="number" step="0.01" className="form-control" {...register("MontantDemi", { required: false })} defaultValue={props.tarif.MontantDemi} />
                    </div>
                </div>}
                <div className="form-group row">
                    <label className="col-4 col-form-label">TVA</label>
                    <div className="col-8">
                        <input placeholder="TVA" type="number" step="0.01" className="form-control"   {...register("Tva", { required: false })} defaultValue={props.tarif.Tva} />
                    </div>
                </div>
            </div>


        </Dialog >
    );
}
