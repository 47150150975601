import { useState, useEffect } from "react";
import DlgDelete from "../../common/dialog/DlgDelete";
import DlgTarifEdit from "../../common/dialog/DlgTarifEdit";
import { Tarification } from "../../common/models/tarification";
import { TypePet } from "../../common/models/type-pet";
import { dataService } from "../../services/Services";
import ConfigTarifItem from "./ConfigTarifItem";

export default function ConfigTarif(props: any) {
    const [listTarif, setListTarif] = useState<Tarification[]>([]);
    const [modeVue, setModeVue] = useState("display");
    const [listTypeAnimal, setListTypeAnimal] = useState<TypePet[]>([]);
    const [selectedTypeAnimal, setSelectedTypeAnimal] = useState(0);
    const [selectedTarif, setSelectedTarif] = useState<Tarification>(new Tarification());

    useEffect(() => {

        dataService.Configuration.getListCategorie().then((response) => { initData(response.data) });

    }, []);

    const openDlgNew = async () => {

        let tarification = new Tarification();
        tarification.Categorie = selectedTypeAnimal;
        setSelectedTarif(tarification)
        setModeVue("edit");
    }
    const onOpenDlgDelete = async (tarification:Tarification) => {
        setSelectedTarif(tarification);
        setModeVue("delete");
    }

    const onOpenDlgEdit = async (tarification:Tarification) => {
        setSelectedTarif(tarification);
        setModeVue("edit");
    }
    const onCloseDlg = async () => {
        setModeVue("display");
    }
    
    const initData = (listType:TypePet[])=>{
        setSelectedTypeAnimal(listType[0].Id);
        setListTypeAnimal(listType)
        loadData(listType[0].Id);
    }
    const loadData = (typeAnimal: number) => {

        dataService.Tarifications.listTarification(typeAnimal).then((response) => { setListTarif(response.data) });
    }

    const onSelectedTypeChange = (event: any) => {
        setSelectedTypeAnimal(parseInt(event.target.value));
        loadData(parseInt(event.target.value));
    }

    const onDelete = async () => {     
        dataService.Tarifications.deleteTarification(selectedTarif.Id).then((response) => {   onCloseDlg();loadData(selectedTypeAnimal)});
    }   
    
    const onValid = async (tarification:Tarification) => {     
        dataService.Tarifications.saveTarification(tarification).then((response) => {    onCloseDlg(); loadData(selectedTypeAnimal); });
    }  
    

    return (
        <div className="card  b">
            <div className="card-content ">
                <div className="card-header d-flex justify-content-between">
                    <h3>Liste des tarifs </h3>
                    <select className="form-control inTitle" onChange={(event) => onSelectedTypeChange(event)} value={selectedTypeAnimal}>
                        <option  value={-1}>Autre</option>
                        {
                            listTypeAnimal && listTypeAnimal.map((type) =>
                                <option key={type.Id} value={type.Id}>{type.Libelle}</option>
                            )}
                    </select>
                </div>
                <div className="card-body">
                    <div className="contentBox limit-height">
                        {listTarif&& listTarif.map((tarif,index)=> 
                            <ConfigTarifItem tarif={tarif} key={index} onOpenDlgDelete={onOpenDlgDelete} onOpenDlgEdit={onOpenDlgEdit} />
                        )}
                </div>

                <hr className="d-print-none" />
                <div className="card-action clearfix">
                    <button type="button" className="btn btn-outline-primary" onClick={openDlgNew} >Ajouter</button>
                </div>

            </div>
        </div>
            { modeVue==="delete" && <DlgDelete onClose={onCloseDlg}   libelle={selectedTarif.Libelle} onDelete={onDelete}/> }
            { modeVue==="edit" && <DlgTarifEdit onClose={onCloseDlg}   tarif={selectedTarif} onValid={onValid} /> }

      </div >);

};

