import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { dataService } from "../../services/Services";
import { useNavigate, useParams } from "react-router-dom";
import { getDateFromTimeStamp } from "../../utils/Tools";
import DatePicker from "react-datepicker";
import { PetVaccine } from "../../common/models/pet-vaccine";
import { Vaccine } from "../../common/models/vaccine";
import { Pet } from "../../common/models/pet";
import { Relation } from "../../common/models/relation";


export default function PetRelationEdit(props: any) {

  let history = useNavigate();
  const params = useParams()

  const { reset, register, handleSubmit } = useForm();

  const [petId, setPetId] = useState(0);
  const [listPet, setListPet] = useState<Pet[]>([]);
  const [filterPet, setFilterPet] = useState("");
  const [refreshId, setRefreshId] = useState(0);

  useEffect(() => {
    loadData();
  }, [params]);


  const loadData = async () => {

    let type = 0;
    let petid = 0;

    if (params.type !== undefined)
      type = parseInt(params.type);
    if (params.petid !== undefined)
      petid = parseInt(params.petid);

    if (petid > 0) {
      var restPet = await dataService.Pets.listPet(type);
      var restRelation = await dataService.Pets.listRelation(petid, 0);
      setPetId(petid);
      restPet.data.forEach((animal: Pet) => {
        if (animal.Id !== petid) {
          let relation = restRelation.data.find((x: Relation) => x.IdAnimal1 === animal.Id || x.IdAnimal2 === animal.Id);
          if (relation === undefined) {
            animal.Relation = 0;// listPetDispo.push(animal);
          } else {
            if (relation.TypeRelation === 1)
              animal.Relation = 1;//;listRelationAmical.push(relation)
            else if (relation.TypeRelation === 2)
              animal.Relation = 2;//listRelationRivaux.push(relation)
          }
        }
        //animal.Relation = true;
      });
      setListPet(restPet.data);

    }
  }

  const filterChange = (event: any) => {
    setFilterPet(event.target.value);
}

  const onSubmit = (data: any) => {
    var pet = data;
    //   pet.Id = props.infoCompl.Id;
   // props.onValid(pet);
  }


  const removeRelation = async (typerelation: number, idSelectAnimal: number) => {
    dataService.Pets.deleteRelation(typerelation, petId, idSelectAnimal).then(result => 
        {
            if(result.data.CodeError===0){
                var petFin = listPet.find(x=>x.Id===idSelectAnimal);
                if(petFin)
                petFin.Relation=0;               
                setRefreshId(refreshId+1);
            }

        }
        );
}

const addToFriend = async (idSelectAnimal: number) => {
    dataService.Pets.insertRelation(1, petId, idSelectAnimal).then(result => 
        {
            if(result.data.CodeError===0){
                var petFin = listPet.find(x=>x.Id===idSelectAnimal);
                if(petFin)
                petFin.Relation=1;
               setRefreshId(refreshId+1);
            }

        }
        );
}
const addToUnFriend = async (idSelectAnimal: number) => {
    dataService.Pets.insertRelation(2, petId, idSelectAnimal).then(result => 
        {
            if(result.data.CodeError===0){
                var petFin = listPet.find(x=>x.Id===idSelectAnimal);
                if(petFin)
                petFin.Relation=2;
                setRefreshId(refreshId+1);
            }

        }
        );
}



  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="container-fluid margtop"  >
        <h4>Relation avec les congénères</h4>
        <div className="dlg_pet_relation">
          <div className="form-group row">
            <div className="col-12">
              <input placeholder="Filtrer le nom de l'animal" name="filterPet" type="text" className="form-control" value={filterPet} onChange={filterChange} />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-4">
              <div className="text-center text-bold">Liste des animaux</div>
              <div className="table-responsive limit-height">
                <table className="table">
                  <tbody>

                    {listPet && listPet.map(animal => (
                      animal.Relation === 0 && (filterPet === "" || animal.Nom.toLowerCase().search(filterPet.toLowerCase()) !== -1) && <tr key={animal.Id} >
                        <td> {animal.Nom} ({animal.NomProprietaire})</td>
                        <td className='div_action'>
                          <div className="d-flex">
                            <div className="pointer btn_action text-success margright" onClick={(event) => addToFriend(animal.Id)}>  <i className="fa-solid fa-face-laugh" /></div>
                            <div className="pointer btn_action text-danger" onClick={(event) => addToUnFriend(animal.Id)}> <i className="fa-solid fa-face-angry" /></div>
                          </div>
                        </td>
                      </tr>)
                    )

                    }
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-4">
              <div className="text-center text-bold">Amis</div>
              <div className="table-responsive limit-height">
                <table className="table">
                  <tbody>
                    {listPet && listPet.map(animal => (
                      animal.Relation === 1 && <tr key={animal.Id} >
                        <td> {animal.Nom} ({animal.NomProprietaire})</td>
                        <td className='div_action'>
                          <div className="pointer btn_action text-danger" onClick={(event) => removeRelation(1, animal.Id)}> <i className="fa-solid fa-xmark" /></div>
                        </td>
                      </tr>))

                    }

                  </tbody>
                </table>
              </div>
            </div>


            <div className="col-4">
              <div className="text-center text-bold">Rivaux</div>
              <div className="table-responsive limit-height">
                <table className="table">
                  <tbody>


                    {listPet && listPet.map(animal => (
                      animal.Relation === 2 && <tr key={animal.Id} >
                        <td> {animal.Nom} ({animal.NomProprietaire})</td>
                        <td className='div_action'>

                          <div className="pointer btn_action text-danger" onClick={(event) => removeRelation(2, animal.Id)}> <i className="fa-solid fa-xmark" /></div>

                        </td>
                      </tr>))

                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="ligne">
            <div id="editEtatmessageError" className="text-danger"></div>
          </div>
        </div >
        <div className="d-flex justify-content-end margtop margbottom">
          {/* <input className="btn btn-outline-primary margright" type="submit" value="Valider" /> */}
          <div className="btn btn-outline-secondary margright" onClick={() => history(-1)} >Retour</div>
        </div>
      </div>
    </form>
  );
}