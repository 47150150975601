import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Proprio } from "../../common/models/proprio";
import { dataService } from "../../services/Services";
import DlgProprioEdit from "../../common/dialog/DlgProprioEdit";

export default function ProprioList(props: any) {

    const [listProprio, setListProprio] = useState<Proprio[]>([]);
    const [filterText, setFilterText] = useState("");
    const [modeVue, setModeVue] = useState("display");
    const [newProprio, setNewProprio] = useState(new Proprio());
    const [loading, setLoading] = useState(true);


    let history = useNavigate();

    useEffect(() => {

        let proprioTmp = new Proprio();
        proprioTmp.Id = -1;
        proprioTmp.Adresse = "";
        proprioTmp.IsDCD = false;
        proprioTmp.NumPortable = "";
        proprioTmp.NumPortable2 = "";
        proprioTmp.NumTel = "";
        proprioTmp.AutreContactName = "";
        proprioTmp.AutreContactTel = "";
        proprioTmp.Email = "";
        proprioTmp.Nom = "";
        proprioTmp.ConnuPar = "";
        setNewProprio(proprioTmp);

        dataService.Proprios.listProprioTel().then((response) => { loadProprio(response.data); });

    }, []);


    const loadProprio = async (listProprio: Proprio[]) => {
        listProprio.forEach(function (proprio, index) { proprio.IsVisible = true; });// nbItemByPage>=index;});
        setLoading(false);
        setListProprio(listProprio);
    }



    const handleChange = (event: any) => {
        setFilterText(event.target.value);
    }
    const search = async () => {
        setFilterText(filterText);
    }

    const openDlgNew = async () => {
        setModeVue("new");
    }


    const onCloseDlg = async () => {
        setModeVue("display");
    }

    const onValid = async (proprio: Proprio) => {
        dataService.Proprios.saveProprio(proprio).then((response) => { onCloseDlg(); history('/proprio/' + response.data.IdResult); });
    }

    if (loading) {
        return(<div>Chargement en cours...</div>);
    } else {
        return (

            <div>
                <div>
                    <div className="filter-header d-flex " >
                        <div className="input-group margright">
                            <input type="text" className="form-control" value={filterText} placeholder="Rechercher sur propriétaires, email  ou numéro de téléphone" onChange={(event) => handleChange(event)} />
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary" type="button" onClick={search}>
                                    <i className="fa-solid fa-magnifying-glass" />
                                </button>
                            </div>
                        </div>
                        <NavLink to={'/proprio-edit/0'}>
                            <div className="btn btn-outline-primary">Créer</div>
                        </NavLink>
                    </div>
                </div>
                <div className="container-fluid" >
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="content-list-proprio">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Nom</th>
                                                <th>Email</th>
                                                <th>Téléphone</th>
                                                <th>Portable</th>
                                                <th>Portable 2</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listProprio !== undefined && listProprio.map((proprioItem) => (
                                                (filterText === null || filterText === undefined
                                                    || (proprioItem.Nom && proprioItem.Nom.toString().toLowerCase().search(filterText.toLowerCase()) !== -1)
                                                    || (proprioItem.NumPortable && proprioItem.NumPortable.toString().toLowerCase().search(filterText.toLowerCase()) !== -1)
                                                    || (proprioItem.NumPortable2 && proprioItem.NumPortable2.toString().toLowerCase().search(filterText.toLowerCase()) !== -1)
                                                    || (proprioItem.NumTel && proprioItem.NumTel.toString().toLowerCase().search(filterText.toLowerCase()) !== -1)
                                                    || (proprioItem.AutreContactTel && proprioItem.AutreContactTel.toString().toLowerCase().search(filterText.toLowerCase()) !== -1)
                                                    || (proprioItem.Email && proprioItem.Email.toString().toLowerCase().search(filterText.toLowerCase()) !== -1)) &&
                                                <tr key={proprioItem.Id}>
                                                    <td>
                                                        <NavLink to={'/proprio/' + proprioItem.Id}>
                                                            <span className='pointerBold' >
                                                                {proprioItem.Nom}
                                                            </span>
                                                        </NavLink>
                                                    </td>
                                                    <td>{proprioItem.Email}</td>
                                                    <td>{proprioItem.NumTel}</td>
                                                    <td>{proprioItem.NumPortable}</td>
                                                    <td>{proprioItem.NumPortable2}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {modeVue === "new" && <DlgProprioEdit onClose={onCloseDlg} proprio={newProprio} onValid={onValid} />}
            </div>
        );
    }
}