import { useState, useEffect } from "react";
import DlgBoxEdit from "../../common/dialog/DlgBoxEdit";
import DlgDelete from "../../common/dialog/DlgDelete";
import { Box } from "../../common/models/box";
import { TypePet } from "../../common/models/type-pet";
import { dataService } from "../../services/Services";
import ConfigBoxItem from "./ConfigBoxItem";

export default function ConfigBox(props: any) {
    const [listBox, setListBox] = useState<Box[]>([]);
    const [modeVue, setModeVue] = useState("display");
    const [listTypeAnimal, setListTypeAnimal] = useState<TypePet[]>([]);
    const [selectedTypeAnimal, setSelectedTypeAnimal] = useState(0);
    const [selectedBox, setSelectedBox] = useState<Box>(new Box());

    useEffect(() => {

        dataService.Configuration.getListCategorie().then((response) => { initData(response.data) });

    }, []);

    const openDlgNew = async () => {

        let box = new Box();
        box.CategorieAnimal = selectedTypeAnimal;
        setSelectedBox(box)
        setModeVue("edit");
    }
    const onOpenDlgDelete = async (box:Box) => {
        setSelectedBox(box);
        setModeVue("delete");
    }

    const onOpenDlgEdit = async (box:Box) => {
        setSelectedBox(box);
        setModeVue("edit");
    }
    const onCloseDlg = async () => {
        setModeVue("display");
    }
    
    const initData = (listType:TypePet[])=>{
        setSelectedTypeAnimal(listType[0].Id);
        setListTypeAnimal(listType)
        loadData(listType[0].Id);
    }
    const loadData = (typeAnimal: number) => {

        dataService.Boxs.listBox(typeAnimal).then((response) => { setListBox(response.data) });
    }

    const onSelectedTypeChange = (event: any) => {
        setSelectedTypeAnimal(Number(event.target.value));
        loadData(Number(event.target.value));
    }

    const onDelete = async () => {     
        dataService.Boxs.deleteBox(selectedBox.Id).then((response) => {   onCloseDlg();loadData(selectedTypeAnimal)});
    }   
    
    const onValid = async (box:Box) => {     
        dataService.Boxs.saveBox(box).then((response) => {    onCloseDlg(); loadData(selectedTypeAnimal); });
    }  
    

    return (
        <div className="card  b">
            <div className="card-content ">
                <div className="card-header d-flex justify-content-between">
                    <h3>Liste des box </h3>
                    <select className="form-control inTitle" onChange={(event) => onSelectedTypeChange(event)} defaultValue={selectedTypeAnimal}>
                        {
                            listTypeAnimal && listTypeAnimal.map((type) =>
                                <option key={type.Id} value={type.Id}>{type.Libelle}</option>
                            )}
                    </select>
                </div>
                <div className="card-body">
                    <div className="contentBox limit-height">
                        {listBox&& listBox.map((box,index)=> 
                            <ConfigBoxItem box={box} key={index} onOpenDlgDelete={onOpenDlgDelete} onOpenDlgEdit={onOpenDlgEdit} />
                        )}
                </div>

                <hr className="d-print-none" />
                <div className="card-action clearfix">
                    <button type="button" className="btn btn-outline-primary" onClick={openDlgNew} >Ajouter</button>
                </div>

            </div>
        </div>
            { modeVue==="delete" && <DlgDelete onClose={onCloseDlg}   libelle={selectedBox.Nom} onDelete={onDelete}/> }
            { modeVue==="edit" && <DlgBoxEdit onClose={onCloseDlg}   box={selectedBox} onValid={onValid} /> }

      </div >);

};

