import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { dataService } from "../../services/Services";
import { useNavigate, useParams } from "react-router-dom";
import { getDateFromTimeStamp } from "../../utils/Tools";
import DatePicker from "react-datepicker";
import { PetVaccine } from "../../common/models/pet-vaccine";
import { Vaccine } from "../../common/models/vaccine";


export default function PetVaccinEdit(props: any) {

  let history = useNavigate();
  const params = useParams()

  const { reset, register, handleSubmit } = useForm();
  const [vaccineInfo, setVaccineInfo] = useState<PetVaccine>(new PetVaccine());
  const [listVaccine, setListVaccine] = useState<Vaccine[]>([]);
  const [messsageError, setMessageError] = useState("");
  
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    loadData();
  }, [params]);


  useEffect(() => {
    reset(vaccineInfo);
  }, [vaccineInfo]);

  const loadData = async () => {
    let id = 0;
    let type = 0;
    let petid = 0;

    if (params.id !== undefined)
      id = parseInt(params.id);
    if (params.type !== undefined)
      type = parseInt(params.type);
    if (params.petid !== undefined)
      petid = parseInt(params.petid);

    if (id > 0) {
      dataService.Pets.getInfoVaccinePet(id).then((response) => { computeInfo(response.data) });
    } else {
      newVaccine(type, petid)
    }
  }

  const computeInfo = async (info: PetVaccine) => {
    var restVaccine = await dataService.Pets.getListVaccine(info.CategorieAnimal);
    setListVaccine(restVaccine.data);
    setSelectedDate(getDateFromTimeStamp(info.DateRealisation));
    setVaccineInfo(info);
  }


  const newVaccine = async (type: number, petId: number) => {

    let newItem = new PetVaccine();
    newItem.Id = -1;
    newItem.CategorieAnimal = type;
    newItem.IdAnimal = petId;
    newItem.DureeValid=36;
    newItem.DateRealisation = new Date().getTime() / 1000;;
    computeInfo(newItem);

  }

  const onSubmit = (data: any) => {
    setMessageError("");
    var vaccine = data;
    vaccine.Id = vaccineInfo.Id;
    vaccine.IdAnimal = vaccineInfo.IdAnimal;
    vaccine.DateRealisation = (selectedDate.getTime()) / 1000;
    if(vaccine.IdVaccin>0)
        dataService.Pets.saveVaccinePet(vaccine).then((response) => { history(-1); });
    else
    setMessageError("Veuillez choisir un vaccin");
  }



  const setDate = (newDate: any) => {
    if (newDate != null) {
      setSelectedDate(newDate);
    }
  }



  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="container-fluid margtop"  >
        <h4>Information vaccin</h4>
        <div className="">
          <div className="form-group ">
            <label >Vaccin</label>
            <select className="form-control" {...register("IdVaccin", { required: false })} >
              {listVaccine && listVaccine.map((b) => <option key={b.Id} value={b.Id}>{b.Libelle}</option>)}
            </select>
          </div>
          <div className="form-group ">
            <label >Date de réalisation</label>
            <DatePicker selected={selectedDate} className="form-control" onChange={(selectdate) => setDate(selectdate)} dateFormat="dd/MM/yyyy" />
          </div>
          <div className="form-group ">
            <label >Validité (en mois) </label>
            <input type='number' className="form-control" {...register("DureeValid", { required: false })} />
          </div >
          <div className="ligne">
            <div id="editEtatmessageError" className="text-danger">{messsageError}</div>
          </div>
        </div >
        <div className="d-flex justify-content-end margtop margbottom">
          <input className="btn btn-outline-primary margright" type="submit" value="Valider" />
          <div className="btn btn-outline-secondary margright" onClick={() => history(-1)} >Annuler</div>
        </div>
      </div>
    </form>
  );
}