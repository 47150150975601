import React, { useEffect, useState } from 'react';
import { Pet } from '../../common/models/pet';
import { dataService } from '../../services/Services';
import { NavLink, } from 'react-router-dom';
import { Relation } from '../../common/models/relation';
import DlgPetRelation from '../../common/dialog/DlgPetRelation';

export default function PetRelation(props: { petId: number, typeRelation: number, petType: number }) {
  const [listRelation, setListRelation] = useState<Relation[]>([])
  const [modeVue, setModeVue] = useState("display");

  useEffect(() => {
    loadData();

  }, [props.petId]);

  const loadData = async () => {
    dataService.Pets.listRelation(props.petId, props.typeRelation).then((response) => { setListRelation(response.data); });
  }
  const onCloseDlg = async () => {
    loadData();
    setModeVue("display");
  }

  const openDlgEdit = async () => {
    setModeVue("edit");
  }


  const onValid = async (pet: Pet) => {
    dataService.Pets.savePet(pet).then((response) => { onCloseDlg(); loadData(); });
  }

  return (
    <div className="card b">
      <div className="card-content ">
        <div className="card-header">
          <h3 >{props.typeRelation === 1 ? "Mes copains" : "Mes rivaux"}</h3>
        </div>
        <div className="card-body  ">
          <div className="limit250">
            {
              listRelation && listRelation.map((relation,index) =>
              <div key={index}>
                <NavLink key={relation.IdAnimal2} to={'/pet/' + props.petType + "/" + relation.IdAnimal2}>
                  <span className='pointerBold' >
                    {relation.NomAnimal2}
                  </span>
                </NavLink>
                </div>
              )}
          </div>
          <hr className="d-print-none" />
          <div className="card-action clearfix">
          <NavLink  to={'/pet-relation-edit/' + props.petId + "/" + props.petType}>
            <div  className="btn btn-outline-primary"  >Modifier</div>
            </NavLink>
          </div>
        </div>
      </div>
      {modeVue==="edit" && <DlgPetRelation petType={props.petType} petId={props.petId} onClose={onCloseDlg} onValid={onValid}   />}
    </div >


  );
}