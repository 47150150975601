import { NavLink } from "react-router-dom";
import { PetVaccine } from "../../common/models/pet-vaccine";
import {  getDateStr2FromTimeStamp, getDateTimeStrFromTimeStamp } from "../../utils/Tools"

export default function PetVaccinItem(props: { vaccine: PetVaccine, typeId: number,onEditVaccine:any, onDeleteVaccine:any}) {

    
    const openDlgEdit = async () => {
        props.onEditVaccine(props.vaccine)
    }
    const openDlgDelete = async () => {
        props.onDeleteVaccine(props.vaccine)
    }
    return (
        <li className='list-group-item'  >
            <div className='d-flex justify-content-between line div_action'>
                <div>
                    <div>{props.vaccine.Libelle}</div>
                    <div className='d-flex justify-content-between'>
                        <div>  {getDateStr2FromTimeStamp(props.vaccine.DateRealisation)} ({props.vaccine.DureeValid} mois)</div>
                    </div>

                </div>
                <div className=''>
                    <div className="d-flex">
               
                    <img src="/img/planimalin_delete.svg" alt="X" className="pointer  btn_action" onClick={openDlgDelete} />
                    <NavLink to={'/pet-vaccin-edit/'+props.vaccine.Id+'/'+props.vaccine.IdAnimal+'/'+props.typeId}>
                        <img src="/img/planimalin_modify.svg" alt="E" className="pointer  btn_action" />
                    </NavLink>
                    
               
                       
                    </div>
                </div>
            </div>
        </li>
    );
}