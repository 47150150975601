import { useEffect, useState } from "react";
import { Dialog } from "../../components/Dialog";
import { useForm } from "react-hook-form";
import { dataService } from "../../services/Services";
import DatePicker from "react-datepicker";
import { getDateFromTimeStamp } from "../../utils/Tools";
import { Pet } from "../../common/models/pet";
import { TypePet } from "../../common/models/type-pet";
import { useNavigate, useParams } from "react-router-dom";


export default function PetEdit(props: any) {

  let history = useNavigate();
  const params = useParams()

  const { reset,register, setValue, handleSubmit } = useForm();
  const [petInfo, setPetInfo] = useState<Pet>(new Pet());
  const [listType, setListType] = useState<TypePet[]>([]);
  const [checkedTraitement, setCheckedTraitement] = useState(false);
  const [checkedSterile, setCheckedSterile] = useState(false);
  const [checkedDcd, setCheckedDcd] = useState(false);
  const [selectedDateNaissance, setSelectedDateNaissance] = useState(new Date());

  useEffect(() => {

    loadData();

  }, [params]);

  
  useEffect(() => {

    reset(petInfo);

  }, [petInfo]);



  const loadData = async () => {
    if (params.id !== undefined) {
      if (parseInt(params.id) > 0) {
        dataService.Pets.getPet(parseInt(params.id)).then((response) => { computePet(response.data) });
      }
      else {
        newPet();
      }
    }
  }

  const computePet = async (pet: Pet) => {
    
    var restpetType = await dataService.Configuration.getListCategorie();
    setListType(restpetType.data);
    if(pet.Type===-1)
      pet.Type=restpetType.data[0].Id;
    setValue("Type", pet.Type);   
    setCheckedDcd(pet.IsDCD);
    setCheckedTraitement(pet.HasTraitement);
    setCheckedSterile(pet.HasSterilise);
    setSelectedDateNaissance(getDateFromTimeStamp(pet.DateNaissance));
    setPetInfo(pet);
    
  }

  const newPet = async () => {
    
    let newPet = new Pet();
    newPet.Id = -1;
    newPet.Type = -1;
    newPet.Sexe = 0;
    if(params.proprioid !== undefined)
      newPet.IdProprietaire = parseInt(params.proprioid);
    newPet.Nom = "";
    newPet.DateLastVaccin = new Date().getTime() / 1000;//TODO : a définir
    newPet.DateNaissance = new Date().getTime() / 1000//TODO : a définir
    newPet.HasSterilise = false;
    newPet.HasTraitement = false;

    newPet.Identification = "";
    newPet.IsDCD = false;
    newPet.Particularite = "";
    newPet.Race = "";
    newPet.Traitement = "";
    newPet.Provenance = "";

    newPet.Robe = "";
    newPet.Vaccin = "";
    computePet(newPet);
  }

  const onSubmit = (data: any) => {
    var pet = data;
    pet.Id = petInfo.Id;
    pet.IdProprietaire = petInfo.IdProprietaire;
    pet.IsDCD = checkedDcd;
    pet.HasSterilise = checkedSterile
    pet.HasTraitement = checkedTraitement
    pet.DateNaissance = (selectedDateNaissance.getTime()) / 1000;
    pet.Vaccin = petInfo.Vaccin;
    pet.DateLastVaccin = petInfo.DateLastVaccin;
    if (pet.Traitement === undefined)
      pet.Traitement = petInfo.Traitement;

    dataService.Pets.savePet(pet).then((response) => { history(-1); });
  }


  const handleChangeTraitement = () => {

    setCheckedTraitement(!checkedTraitement);

  };
  const handleChangeSterile = () => {

    setCheckedSterile(!checkedSterile);

  };
  const handleChangeDcd = () => {

    setCheckedDcd(!checkedDcd);

  };

  const setDateNaissance = (newDate: any) => {
    if (newDate != null) {
      setSelectedDateNaissance(newDate);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="container-fluid margtop"  >
        <h4>{petInfo.Id > 0 ? "Modification animal" : "Ajout animal"}</h4>
        <div className="">
          <div className="form-group">
            <label >Nom</label>
            <input  placeholder="Nom" type="text" className="form-control"  {...register("Nom", { required: false })}  />
          </div>
          <div className="form-group">
            <label >Type</label>
            <select className="form-control"  {...register("Type", { required: false })}  >
              {listType && listType.map((item) => (<option key={item.Id} value={item.Id}>{item.Libelle}</option>))}
            </select>
          </div>
          <div className="form-group">
            <label >Sexe</label>
            <select className="form-control"   {...register("Sexe", { required: false })} >
              <option value='0'>Male</option>
              <option value='1'>Femelle</option>
            </select>

          </div>

          <div className="form-group">
            <label >Date naissance</label>
            <DatePicker selected={selectedDateNaissance} className="form-control" onChange={(date) => setDateNaissance(date)} dateFormat="dd/MM/yyyy" />
          </div>
          <div className="form-group">
            <label >Race</label>
            <input placeholder="Race" type="text" className="form-control" {...register("Race", { required: false })} />
          </div>
          <div className="form-group">
            <label >Robe</label>
            <input placeholder="Robe" type="text" className="form-control"  {...register("Robe", { required: false })} />
          </div>
          <div className="form-group">
            <label >Identification</label>
            <input placeholder="Identification" type="text" className="form-control"   {...register("Identification", { required: false })}  />
          </div>
          <div className="form-group">
            <label >Provenance</label>
            <input placeholder="Provenance" type="text" className="form-control" {...register("Provenance", { required: false })} />

          </div>
          <div className="form-group">
            <label >Particularité</label>
            <textarea placeholder="Particularité de l'animal" className="form-control note-editor" rows={4} {...register("Particularite", { required: false })}  />
          </div>
          <div className="d-flex justify-content-between">
            <div >
              <div className="form-group form-check">
                <input type="checkbox" className="form-check-input" checked={checkedTraitement} {...register('HasTraitement', { required: false })} onChange={handleChangeTraitement} />
                <label className="form-check-label">Traitement en cours</label>
              </div>
            </div>
            <div >
              <div className="form-group form-check">
                <input type="checkbox" className="form-check-input" checked={checkedSterile} {...register('HasSterilise', { required: false })} onChange={handleChangeSterile} />
                <label className="form-check-label" >Est stérilisé</label>
              </div>
            </div>
            <div >
              <div className="form-group form-check">
                <input type="checkbox" className="form-check-input" checked={checkedDcd} {...register('IsDCD', { required: false })} onChange={handleChangeDcd} />
                <label className="form-check-label" >Est décédé</label>
              </div>
            </div>
          </div>
          {checkedTraitement && <div className="form-group">
            <label >Traitement</label>
            <textarea placeholder="Indication sur le traitement" className="form-control note-editor" rows={8}  {...register("Traitement", { required: false })} defaultValue={petInfo.Traitement} />
          </div>}
          <div className="ligne">
            <div id="editEtatmessageError" className="text-danger"></div>
          </div>
        </div >
        <div className="d-flex justify-content-end margtop margbottom">
          <input className="btn btn-outline-primary margright" type="submit" value="Valider" />
          <div className="btn btn-outline-secondary margright" onClick={() => history(-1)} >Annuler</div>
        </div>
      </div>
    </form>
  );
}