import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { dataService } from "../../services/Services";
import { Pet } from "../../common/models/pet";

export default function PetList(props: any) {

    const [listPet, setListPet] = useState<Pet[]>([]);
    const [filterText, setFilterText] = useState("");
    const [loading, setLoading] = useState(true);
    const params = useParams();

    useEffect(() => {


    }, [props.proprioId]);

    useEffect(() => {
        if (params.petType !== undefined)
            dataService.Pets.listPetFull(Number.parseInt(params.petType)).then((response) => { loadPet(response.data); });

    }, [params]);


    const loadPet = async (listPet: Pet[]) => {
        listPet.forEach(function (pet, index) { pet.IsVisible = true; });// nbItemByPage>=index;});
        setLoading(false);
        setListPet(listPet);
    }

    const openDlgNew = async () => {

    }

    const handleChange = (event: any) => {
        setFilterText(event.target.value);
    }
    const search = async () => {
        setFilterText(filterText);
    }

    if (loading) {
        return (<div>Chargement en cours...</div>);
    } else {
        return (
            <div>
                <div>
                    <div className="filter-header d-flex " >
                        <div className="input-group margright">
                            <input type="text" className="form-control" value={filterText} placeholder="Rechercher sur nom, identification ou nom propriétaire" onChange={(event) => handleChange(event)} />
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary" type="button" onClick={search}>
                                    <i className="fa-solid fa-magnifying-glass" />
                                </button>
                            </div>
                        </div>
                        {/* <div className="btn btn-outline-primary " onClick={openDlgNew}>Créer</div> */}
                    </div>
                </div>
                <div className="container-fluid" >
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="content-list-proprio">

                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Nom</th>
                                                <th>Propriétaire</th>
                                                <th>Race</th>
                                                <th>Robe</th>
                                                <th>Identification</th>
                                                <th>Sexe</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listPet !== undefined && listPet.map((petItem) => (
                                                (filterText === null || filterText === undefined
                                                    || (petItem.Nom && petItem.Nom.toString().toLowerCase().search(filterText.toLowerCase()) !== -1)
                                                    || (petItem.Identification && petItem.Identification.toString().toLowerCase().search(filterText.toLowerCase()) !== -1)
                                                    || (petItem.NomProprietaire && petItem.NomProprietaire.toString().toLowerCase().search(filterText.toLowerCase()) !== -1)) &&
                                                <tr key={petItem.Id}>
                                                    <td>
                                                        <NavLink to={'/pet/' + petItem.Type + "/" + petItem.Id}>
                                                            <span className='pointerBold' >
                                                                {petItem.Nom}
                                                            </span>
                                                        </NavLink>
                                                    </td>
                                                    <td>
                                                        <NavLink to={'/proprio/' + petItem.IdProprietaire}>
                                                            <span className='pointerBold' >
                                                                {petItem.NomProprietaire}
                                                            </span>
                                                        </NavLink>
                                                    </td>
                                                    <td>{petItem.Race}</td>
                                                    <td>{petItem.Robe}</td>
                                                    <td>{petItem.Identification}</td>
                                                    <td>{petItem.Sexe === 0 ? "Mâle" : "Femelle"}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}