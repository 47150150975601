import {  useEffect, useState } from "react";
import { Dialog } from "../../components/Dialog";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { getDateFromTimeStamp } from "../../utils/Tools";
import { Invoice } from "../models/invoice";


export default function DlgPaiementEdit(props: { invoice: Invoice, onClose: any, onValid: any }) {


    const { register,  handleSubmit } = useForm();
    const [selectedDatePaiement, setSelectedDatePaiement] = useState(new Date())
    const [checkedSendMailRecapPaiement, setCheckedSendMailRecapPaiement] = useState(false); 


    useEffect(() => {
        if (props.invoice !== undefined) {
            if(props.invoice.PaiementDate>0)
                setSelectedDatePaiement(getDateFromTimeStamp(props.invoice.PaiementDate));
            setCheckedSendMailRecapPaiement(props.invoice.PaiementMail===1);



        }
    }, [props.invoice]);

    const handleChangeMailRecap = () => {     
        setCheckedSendMailRecapPaiement(!checkedSendMailRecapPaiement);         
      };

    const onSubmit = (data: any) => {

        var invoice = data;
        invoice.Id = props.invoice.Id;
        invoice.PaiementDate = (selectedDatePaiement.getTime()) / 1000 ;
        invoice.PaiementType = data.PaiementType;
        if(props.invoice.PaiementMail==0)
            invoice.PaiementMail = checkedSendMailRecapPaiement?1:0;
        else
            invoice.PaiementMail = -1
        props.onValid(invoice);
    }

    const onCloseDlg = async () => {

        props.onClose();
    }

    const setDatePaiement = (newDate: any) => {
        if (newDate != null) {
            setSelectedDatePaiement(newDate);
        }
    }

    return (
        <Dialog
            title="Information sur le réglement"
            onClose={onCloseDlg}
            onSubmit={handleSubmit(onSubmit)}
            actionbutton={
                <div>
                    <input className="btn btn-outline-primary margright" type="submit" value="Valider" />
                    <div onClick={onCloseDlg} className="btn btn-outline-secondary" >Annuler</div>
                </div>
            }>
            <div className="dlg_paiement_edit">
                <div className="form-group row">

                    <label className="col-4 col-form-label">Date du règlement</label>
                    <div className="col-8">
                        <DatePicker selected={selectedDatePaiement} className="form-control" onChange={(date) => setDatePaiement(date)} dateFormat="dd/MM/yyyy" />
                    </div>
                </div>
                <div className="form-group row">

                    <label className="col-4 col-form-label">Mode de réglement</label>
                    <div className="col-8">
                        <select className="form-control"  {...register("PaiementType", { required: false })} defaultValue={props.invoice.PaiementType} >
                            <option value='2'>Chèque</option>
                            <option value='3'>Carte bleue</option>
                            <option value='4'>Espèces</option>
                            <option value='5'>Virement</option>
                            <option value='6'>Paypal</option>
                            <option value='1'>Autres</option>
                        </select>
                    </div>
                </div>
                <div className="form-group row">

                    <label className="col-4 col-form-label"></label>
                    <div className="col-8">
                    <div className="form-group form-check">
                    <input  type="checkbox"  className="form-check-input"  checked={checkedSendMailRecapPaiement} disabled={props.invoice.PaiementMail>0} {...register('PaiementMail', { required: false })} onChange={handleChangeMailRecap}   />
                    <label className="form-check-label">Envoyer mail récapitulatif</label>
                </div>

                    </div>
                </div>
                
            </div>


        </Dialog >
    );
}
