import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import DlgVetoEdit from "../../common/dialog/DlgVetoEdit";
import { Proprio } from "../../common/models/proprio";
import { dataService } from "../../services/Services";

export default function ProprioVeto(props: any) {

    const [vetoInfo, setVetoInfo] = useState(new Proprio())
    const [modeVue, setModeVue] = useState("display");

    useEffect(() => {
        loadData();

    }, [props.proprioId]);

   const loadData = async () => {
    dataService.Proprios.getVeto(props.proprioId).then((response) => { setVetoInfo(response.data); });
   }

    const onCloseDlg = async () => {
        setModeVue("display");
    }

   
    const openDlgEdit = async () => {
        setModeVue("edit");
    }
    
    const onValid = async (veto:Proprio) => {     
        dataService.Proprios.saveVeto(veto).then((response) => {    onCloseDlg(); loadData(); });
    }  

    return (
        <div className="card b">
            <div className="card-content ">
                <div className="card-header">
                    <h3>Vétérinaire</h3>
                </div>
                <div className="card-body">
                    <form className="form-horizontal">
                        <div className="form-group row">
                            <div className="col-md-4">Nom</div>
                            <div className="col-md-8">
                                <strong>{vetoInfo.VetoName}</strong>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-4">Adresse</div>
                            <div className="col-md-8">
                                <strong>{vetoInfo.VetoAdresse}</strong>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-4">Téléphone</div>
                            <div className="col-md-8">
                                <strong>{vetoInfo.VetoNumTel}</strong>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-4">Téléphone 2</div>
                            <div className="col-md-8">
                                <strong>{vetoInfo.VetoNumTel2}</strong>
                            </div>
                        </div>
                    </form>
                    <hr className="d-print-none" />
                    <div className="card-action clearfix">
                    <NavLink to={'/proprio-veto-edit/' + vetoInfo.Id}>
                            <div className="btn btn-outline-primary">Modifier</div>
                        </NavLink>
                    </div>
                </div>
            </div>
            { modeVue==="edit" && <DlgVetoEdit onClose={onCloseDlg}   veto={vetoInfo} onValid={onValid}/> }
        </div>
    );
}
