
import { Dialog } from "../../components/Dialog";
import { useForm } from "react-hook-form";


function DlgDelete(props: { libelle:string,onClose:any, onDelete: any }) {

    const {  handleSubmit } = useForm();
    


    const onCloseDlg = async() => {
      
        props.onClose();
    }
    const onSubmit = (data: any) => {
        props.onDelete();
    }

    return (
        <Dialog
            title="Confirmation de suppression"
           // open={openSetState}
            onClose={onCloseDlg}
            onSubmit={handleSubmit(onSubmit)}
            actionbutton={
                <div>
                    <input className="btn btn-outline-danger margright" type="submit" value="Valider" />
                    <div onClick={onCloseDlg} className="btn btn-outline-secondary" >Annuler</div>
                </div>
            }>
            <div className="dlg_delete">
            <label>Etes-vous sûr de vouloir supprimer { props.libelle}</label>

            </div>



        </Dialog >
    );
}
export default DlgDelete;

