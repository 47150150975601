import * as React from 'react';
import { Page } from '../common/models/page';

import { ConfigContextType, IConfigPlanimalin } from './../common/models/config'

export const ConfigContext = React.createContext<ConfigContextType | null>(null);

const ConfigProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [config, setTodos] = React.useState<IConfigPlanimalin>({
        ListPage :[],
        ExistFacture: false,
        ModeTarification :1,
        PensionId:0
    });

  
  const  saveModeTarification= (modeTarification:number) => {
    config.ModeTarification = modeTarification;
    setTodos(config);
};
const saveExistFacture= (existFacture:boolean) => {
    config.ExistFacture = existFacture;
    setTodos(config);
};
const savePensionId= (pensionId:number) => {
  config.PensionId = pensionId;
  setTodos(config);
};



  const savePage = (listPage: Page[]) => {
    config.ListPage = listPage;
        setTodos(config);
  };

  return <ConfigContext.Provider value={{ config, saveModeTarification,saveExistFacture, savePage,savePensionId }}>{children}</ConfigContext.Provider>;
};

export default ConfigProvider;
