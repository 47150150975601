import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Font, Rect, Svg, G } from '@react-pdf/renderer';
import moment from 'moment';
import React, { useState } from 'react';
import { Box } from '../common/models/box';
import { BoxPeriode } from '../common/models/box-periode';
import { InfoDayCalendar } from '../common/models/info-day-calendar';
import { MinMaxDate } from '../common/models/min-max-date';
import { Periode } from '../common/models/periode';
import { clone,  convertMonthToString, convertNumToStringAnimal, getDateStrFileFromTimeStamp, hslToHex } from './Tools';
Font.register({
  family: 'Roboto',
  fonts: [
    { src: '/webfonts/Roboto-Regular.ttf' },

  ]
});

// Create styles

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    fontSize: 10,
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: 16,
    textAlign: 'center',
    marginTop: 5,
    marginBottom: 5,

  },


});


export function CreatePlanning(props: {infoMinMaxDate: MinMaxDate, typeAnimal: number, listperiode: Periode[], lisbox:Box[] }) {
  const [listPage, setListPage] = useState<any[]>([]);
  const fileName='Planning-' + convertNumToStringAnimal(props.typeAnimal) + '-' + getDateStrFileFromTimeStamp(props.infoMinMaxDate.MinDate) + '-' + getDateStrFileFromTimeStamp(props.infoMinMaxDate.MaxDate) + '.pdf';


  React.useEffect(() => {
    
    if(props.listperiode!==undefined && props.lisbox !== undefined && props.listperiode.length>0 && props.lisbox.length>0)
      loadData(props.infoMinMaxDate, props.typeAnimal,props.listperiode, props.lisbox);
  }, [props]);

  const loadData = async (infoMinMaxDate: MinMaxDate, typeAnimal: number,listPeriode: Periode[], listBox:Box[]) => {
    
    listPeriode =listPeriode.map((x:Periode)=>clone(x));
    

    var widthPage = 841;
    var height = 595;
    var widthColBox = 200;
    var heighLine = 30;
  
    // compute planning element
    let dayInSecond = 60 * 60 * 24;
    let widthForOneDay = 0;

    //calcul du calendrier
    let listMonthTmp = [];
    let listDayTmp = [];
    let listWeekendTmp = [];

    let nbDay = Math.trunc((infoMinMaxDate.MaxDate - infoMinMaxDate.MinDate) / (dayInSecond)) + 1;
    if (nbDay > 31)
      nbDay = 31;


    let widthForAllDay = widthPage - widthColBox;
    widthForOneDay = Math.trunc(widthForAllDay / nbDay);

    widthForAllDay = widthForOneDay * nbDay;

    let tailleByUnit = widthForAllDay / (infoMinMaxDate.MaxDate - infoMinMaxDate.MinDate);
    let percentByUnit = 100 / (infoMinMaxDate.MaxDate - infoMinMaxDate.MinDate);



    var posleft = widthColBox;
    var posleftPercent = 0;
    var dateInc = infoMinMaxDate.MinDate;

    //calcul liste numéro de jours
    let currentDay = moment.unix(infoMinMaxDate.MinDate);
    let listNumDay = [];
    for (var d = 0; d < nbDay; d++) {

      let date = new InfoDayCalendar();

      date.Jour = currentDay.date();
      date.Month = currentDay.month();
      date.Year = currentDay.year();
      listNumDay.push(date);
      currentDay.add(1, 'days')
    }


    for (var nd = 0; nd < nbDay; nd++) {
      var info = listNumDay[nd];
      info.Width = widthForOneDay;
      info.Height = heighLine;
      listDayTmp.push(info);

      //calcul mois Info
      let findMonthInfo = listMonthTmp.find(x => x.Month === info.Month + 1 && x.Year === info.Year);
      if (findMonthInfo === undefined) {
        let infoMonth = new InfoDayCalendar();
        if (info.Jour === 1)
          infoMonth.Jour = 1;
        else
          infoMonth.Jour = 0;
        infoMonth.Month = info.Month + 1;
        infoMonth.Year = info.Year;
        infoMonth.Posleft = posleft;
        infoMonth.LeftPercent = posleftPercent;
        infoMonth.Width = widthForOneDay;
        infoMonth.NbDay = 1;
        listMonthTmp.push(infoMonth);
      } else {
        findMonthInfo.Width += widthForOneDay;
        findMonthInfo.NbDay += 1;
      }



      var day = (new Date((dateInc + 3600 * 12) * 1000));
      if (day.getDay() === 0 || day.getDay() === 6) {
        var infowk = new InfoDayCalendar();
        infowk.Posleft = posleft - widthColBox;
        infowk.Width = widthForOneDay;
        infowk.ClassDay = 'timeline_weekend';
        info.ClassDay = 'timeline_weekend';
        listWeekendTmp.push(infowk);
      }


      if (new Date().toLocaleDateString('fr-fr', { year: 'numeric', month: 'long', day: 'numeric' }) === day.toLocaleDateString('fr-fr', { year: 'numeric', month: 'long', day: 'numeric' })) {
        var infotd = new InfoDayCalendar();
        infotd.Posleft = posleft - widthColBox;
        infotd.Width = widthForOneDay;
        infotd.ClassDay = 'timeline_today';
        listWeekendTmp.push(infotd);
      }

      posleft += widthForOneDay;
      posleftPercent += widthForOneDay * 100 / widthForAllDay;
      dateInc += (60 * 60 * 24);
    }

    //calcul des périodes/box
    let listBoxPeriodeTmp = [];
    for (var i = 0; i < listBox.length; i++) {
      var boxPeriode: BoxPeriode = new BoxPeriode();
      boxPeriode.BoxInfo = listBox[i];
      boxPeriode.ListPeriode = listPeriode.filter((x: Periode) => x.IdBox === listBox[i].Id);
      boxPeriode.Height = heighLine;
      var nbPeriodeMaxInSpace = 0;
      for (var j = 0; j < boxPeriode.ListPeriode.length; j++) {
        var periode =boxPeriode.ListPeriode[j];


        let debPer = (moment.unix(periode.DateDebPlan));
        let finPer = (moment.unix(periode.DateFinPlan));

        for (debPer = debPer.startOf('day'); debPer <= finPer; debPer.add(1, 'days')) {
          let dayfind = listDayTmp.find(x => x.Jour === debPer.date() && x.Month === debPer.month() && x.Year === debPer.year());
          if (dayfind) {
            dayfind.NbPet++;
          }
        }



        let diffTs = periode.DateFinPlan - periode.DateDebPlan;

        periode.Left = tailleByUnit * (periode.DateDebPlan - infoMinMaxDate.MinDate);//-(tailleByUnit*60*60);
        periode.Width = tailleByUnit * (diffTs);
        periode.LeftPercent = percentByUnit * (periode.DateDebPlan - infoMinMaxDate.MinDate);
        periode.WidthPercent = percentByUnit * (diffTs);
        periode.Top = 3;
        periode.Line = 0;

        var lineNotEmpty = [];
        for (var k = 0; k < j; k++) {
          var periodePrevious = boxPeriode.ListPeriode[k];
          if ((periodePrevious.Left <= periode.Left && periode.Left <= (periodePrevious.Left + periodePrevious.Width)) ||
            (periodePrevious.Left <= (periode.Left + periode.Width) && (periode.Left + periode.Width) <= (periodePrevious.Left + periodePrevious.Width)) ||
            (periode.Left <= periodePrevious.Left && periodePrevious.Left <= (periode.Left + periode.Width)) ||
            (periode.Left <= (periodePrevious.Left + periodePrevious.Width) && (periodePrevious.Left + periodePrevious.Width) <= (periode.Left + periode.Width))
          ) {

            lineNotEmpty.push(periodePrevious.Line);
          }
        }
        var myPos = -1;
        for (var l = 0; l < lineNotEmpty.length && myPos === -1; l++) {
          if (l !== lineNotEmpty[l]) {
            myPos = l;
          }
        }
        if (myPos === -1)
          myPos = lineNotEmpty.length > 0 ? lineNotEmpty.length : 0;

        if (myPos > 0) {
          periode.Top = 2 + heighLine * myPos;
          periode.Line = myPos;
        }

        if (myPos > nbPeriodeMaxInSpace) {
          nbPeriodeMaxInSpace = myPos;
        }


      }
      if (nbPeriodeMaxInSpace > 0)
        boxPeriode.Height = heighLine + nbPeriodeMaxInSpace * heighLine;

      listBoxPeriodeTmp.push(boxPeriode);

    }
    var listPageTmp = new Array<any>();
    var nbPage = 0;
    var listTmpPeriode = new Array<BoxPeriode>();
    var heightPrev = 100;
    for (var i = 0; i < listBoxPeriodeTmp.length; i++) {
      if (heightPrev + listBoxPeriodeTmp[i].Height > height) {
        listPageTmp.push(<Page key={nbPage} size="A4" style={styles.page} orientation='landscape' >
          <GetHeader selectType={typeAnimal} />
          <Svg style={{ width: '100%', height: height-30+'px' }}>
            {GetPlanning(widthColBox, widthForOneDay, listTmpPeriode, listMonthTmp, listDayTmp,nbPage)}
            {GetPeriodes(listTmpPeriode, widthColBox,nbPage+1)}
          </Svg>
        </Page>);

        // listPageTmp.push(GetPlanning(widthColBox, widthForOneDay, listTmpPeriode, listMonthTmp, listDayTmp));
        //listPageTmp.push(GetPeriodes(listTmpPeriode, widthColBox));
        listTmpPeriode = new Array<BoxPeriode>();
        listTmpPeriode.push(listBoxPeriodeTmp[i]);
        heightPrev = listBoxPeriodeTmp[i].Height;
        nbPage++;
      }
      else {
        heightPrev += listBoxPeriodeTmp[i].Height;
        listTmpPeriode.push(listBoxPeriodeTmp[i]);
      }
    }

    if (listTmpPeriode.length > 0) {
      listPageTmp.push(<Page key={nbPage} size="A4" style={styles.page} orientation='landscape' >
        <GetHeader selectType={typeAnimal} />
        <Svg style={{ width: '100%', height:  height-30+'px' }}>
          {GetPlanning(widthColBox, widthForOneDay, listTmpPeriode, listMonthTmp, listDayTmp,nbPage)}
          {GetPeriodes(listTmpPeriode, widthColBox,nbPage+1)}
        </Svg>
      </Page>);
    }
    setListPage(listPageTmp);
  }

  let MyDocument =
    <Document >
      {listPage && listPage.map((invoiceInfo, index) =>
        invoiceInfo)}
    </Document>;
  return (
    <PDFDownloadLink document={MyDocument} fileName={fileName}>
      {({ url, loading, error }) => {

        if (loading) {
          return (
            <span>
              génération facture...
            </span>
          );
        }
        if (!loading && url) {

          return (

            <div className="btn btn-outline-primary margright " >Générer planning</div>

          );
        }
        if (error) {
          console.error(error);
          return <p>une erreur est survenue</p>;
        }
        return null;
      }}
    </PDFDownloadLink>
  )
}

const GetHeader = (props: { selectType: number }) => (
  <View>
    <Text style={styles.title}> Planning des {convertNumToStringAnimal(props.selectType)}</Text>
  </View>

);


const GetPlanning = (widthColBox: number, widthForOneDaypdf: number, periodeForPage: BoxPeriode[], listMonth: InfoDayCalendar[], listDay: InfoDayCalendar[],nbPage:number) => {

  var listBoxLine =  new Array<any>();
  var decalLeft = 15;
  var posY = 0;
  var left = widthColBox + decalLeft;
let nbkey=0;
  for (var i = 0; i < listMonth.length; i++) {

    listBoxLine.push(
      <Rect x={left}
        y={posY}
        width={listMonth[i].NbDay * widthForOneDaypdf}
        height={30}
        stroke={'black'}
        strokeWidth={1} 
        key={nbkey++}/>
    );
    listBoxLine.push(
      <Text x={left + listMonth[i].NbDay * widthForOneDaypdf / 2 - 25}
        y={posY + 20}
        style={{ fontSize: 9 }}
        key={nbkey++} >
        {convertMonthToString(listMonth[i].Month) + ' ' + listMonth[i].Year}
      </Text>);

    left += listMonth[i].NbDay * widthForOneDaypdf
  }
  posY += 30;
  left = widthColBox + decalLeft;
  for (var i = 0; i < listDay.length; i++) {

    listBoxLine.push(
      <Rect x={left}
        y={posY}
        width={widthForOneDaypdf}
        height={30}
        fill={listDay[i].ClassDay === 'timeline_weekend' ? "#E0E0E0" : "#ffffff"}
        stroke={'black'} //this.listDay[i].ClassDay=='timeline_weekend'?"#E0E0E0":"#ffffff",
        strokeWidth={1} 
        key={nbkey++}/>
    );

    listBoxLine.push(
      <Text x={left + 5} style={{ fontSize: 9 }}
        y={posY + 20}
        key={nbkey++}>
        {listDay[i].Jour}
      </Text>);
    left += widthForOneDaypdf
  }
  posY += 30;

  for (var i = 0; i < periodeForPage.length; i++) {
    //nom box
    listBoxLine.push(
      <Rect x={decalLeft}
        y={posY}
        width={widthColBox}
        height={periodeForPage[i].Height}
        stroke={'black'} //this.listDay[i].ClassDay=='timeline_weekend'?"#E0E0E0":"#ffffff",
        strokeWidth={1} 
        key={nbkey++}/>
    );
    listBoxLine.push(
      <Text x={20} style={{ fontSize: 9 }}
        y={posY + periodeForPage[i].Height / 2}
        key={nbkey++}>
        {periodeForPage[i].BoxInfo.Nom}
      </Text>);

    //day
    left = widthColBox + decalLeft;
    for (var j = 0; j < listDay.length; j++) {
      listBoxLine.push(
        <Rect x={left}
          y={posY}
          width={widthForOneDaypdf}
          height={periodeForPage[i].Height}
          stroke={'black'}
          fill={listDay[j].ClassDay === 'timeline_weekend' ? "#E0E0E0" : "#ffffff"} //this.listDay[i].ClassDay=='timeline_weekend'?"#E0E0E0":"#ffffff",
          strokeWidth={1} 
          key={nbkey++}/>
      );

      left += widthForOneDaypdf
    }

    posY += periodeForPage[i].Height;
  }

  return (
    <G key={nbPage}>
      {listBoxLine && listBoxLine.map((item, index) =>
        item
      )}

    </G>
  )
}


const GetPeriodes = (periodeForPage: BoxPeriode[], widthColBox: number,nbPage:number) => {

  var listBoxLine = new Array<any>();
  var decalLeft = 15;
  var left = widthColBox + decalLeft;
  var posY = 60;
let nbkey=0;
  for (var i = 0; i < periodeForPage.length; i++) {
    var lineInfo = periodeForPage[i];

    for (var j = 0; j < lineInfo.ListPeriode.length; j++) {
      var periodeInfo = lineInfo.ListPeriode[j];
      listBoxLine.push(
        <Rect x={left + periodeInfo.Left}
          y={posY + periodeInfo.Top}
          width={periodeInfo.Width}
          height={23}
          fill={hslToHex(periodeInfo.Color)}
          key={nbkey++}
        />
      );

      var texte = periodeInfo.Nom + '( ' + periodeInfo.NomProprietaire + ')';
      listBoxLine.push(
        <Text x={left + periodeInfo.Left + periodeInfo.Width / 2 - texte.length * 2.5}
          y={posY + periodeInfo.Top + 15}
          style={{ fontSize: 8 }}
          key={nbkey++}>
          {texte}
        </Text>);

    }
    posY += lineInfo.Height;
  }
  return (
    <G  key={nbPage}>
      {listBoxLine && listBoxLine.map((item, index) =>
        item
      )}

    </G>
  )


}

