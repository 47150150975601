import React, { useEffect, useState } from 'react';
import { MinMaxDate } from '../../common/models/min-max-date';
import moment from 'moment';
import { convertMonthToString, getMinMaxWithMonthYear } from '../../utils/Tools';
import { CreatePlanning } from '../../utils/GenPlanningPdf';
import { useNavigate, useParams } from 'react-router-dom';
import { TypePet } from '../../common/models/type-pet';
import { dataService } from '../../services/Services';
import PlanningLine from './PlanningLine';
import { BoxPeriode } from '../../common/models/box-periode';
import { InfoDayCalendar } from '../../common/models/info-day-calendar';
import { Periode } from '../../common/models/periode';
import { Box } from '../../common/models/box';
import PeriodeEditComposant from './PeriodeEditComposant';
import PlanningOther from './PlanningOther';
import { PlanningEvent } from '../../common/models/planning-event';
import EventEditComposant from './EventEditComposant';

export default function Planning(props: any) {
    const [selectedMinMax, setSelectedMinMax] = useState(new MinMaxDate());
    const [listYear, setListYear] = useState<number[]>([]);
    const [listTypePet, setListTypePet] = useState<TypePet[]>([]);

    const [selectedTypePet, setSelectedTypePet] = useState(0);
    const [selectedMonth, setSelectedMonth] = useState(0);
    const [selectedYear, setSelectedYear] = useState(0);

    const [widthCalendar, setWidthCalendar] = useState(0);
    const [heightScheduler, setHeightScheduler] = useState(0);
    const [heightHeader, setHeightHeader] = useState(61);
    const [heightLineEvent, setHeightLineEvent] = useState(52);
    
    const [listMonth, setListMonth] = useState<InfoDayCalendar[]>([]);
    const [listDay, setListDay] = useState<InfoDayCalendar[]>([]);
    const [listWeekend, setListWeekend] = useState<InfoDayCalendar[]>([]);
    const [listBoxPeriode, setListBoxPeriode] = useState<BoxPeriode[]>([]);
    const [modeVue, setModeVue] = useState("display");
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [selectedPeriode, setSelectedPeriode] = useState<Periode>(new Periode());
    const [selectedPlanningEvent, setSelectedPlanningEvent] = useState<PlanningEvent>(new PlanningEvent());

    const [listBox, setListBox] = useState<Box[]>([]);
    const [listPeriode, setListPeriode] = useState<Periode[]>([]);
    const [listEvent, setListEvent] = useState<PlanningEvent[]>([]);

    const widthColBox: number = 200;
    //const refPlanning = useRef(null);
    const weekStepTS = (7 * 24 * 60 * 60);
    const navigate = useNavigate()
    const params = useParams();


    useEffect(() => {

        loadData();
    }, [params]);

    const loadData = async () => {
        setHeightHeader(70);
        var today = moment();

        let dated = -1;
        let datef = -1;
        let typeanimal = -1;
        if (params.dated !== undefined)
            dated = parseInt(params.dated);
        if (params.datef !== undefined)
            datef = parseInt(params.datef);
        if (params.typeanimal !== undefined)
            typeanimal = parseInt(params.typeanimal);

        let selectMinMaxTmp = new MinMaxDate();
        selectMinMaxTmp.MinDate = dated;
        selectMinMaxTmp.MaxDate = datef;

        var restTypePet = await dataService.Configuration.getListCategorie();
        var restMinMax = await dataService.Configuration.getMinMaxDateExploitation();
        setListTypePet(restTypePet.data)

        if (typeanimal < 0) {
            typeanimal = restTypePet.data[0].Id;
            selectMinMaxTmp = getMinMaxWithMonthYear(today.month() + 1, today.year());
        }


        setSelectedMinMax(selectMinMaxTmp);
        setSelectedTypePet(typeanimal);

        var yearMin = moment.unix(restMinMax.data.MinDate).year();
        var yearMax = moment.unix(restMinMax.data.MaxDate).year();
        var listYearTmp = [];
        for (var i = yearMin; i <= (yearMax + 2); i++)
            listYearTmp.push(i);
        setListYear(listYearTmp);
        setSelectedMonth(moment.unix(selectMinMaxTmp.MaxDate).month() + 1);
        setSelectedYear(moment.unix(selectMinMaxTmp.MaxDate).year());
        reloadData(typeanimal, selectMinMaxTmp)


    }
    const reloadData = async (typeanimal: number, selectMinMax: MinMaxDate) => {
        if (typeanimal >= 0 && selectMinMax.MinDate > 0 && selectMinMax.MinDate > 0) {
            let restbox = await dataService.Boxs.listBox(typeanimal);
            let restPeriode = await dataService.Periodes.listMoveDate(typeanimal, selectMinMax.MinDate, selectMinMax.MaxDate);
            let restEvent = await dataService.Periodes.listEvent(selectMinMax.MinDate, selectMinMax.MaxDate);
            setListBox(restbox.data.map((x: Box) => x));
            setListPeriode(restPeriode.data);
            buildPlanning(restEvent.data, restPeriode.data, restbox.data, selectMinMax);
        }
    }

    const previousWeek = () => {
        var minmax = new MinMaxDate();
        minmax.MinDate = selectedMinMax.MinDate - weekStepTS;
        minmax.MaxDate = selectedMinMax.MaxDate - weekStepTS;
        navigate('/planning/' + selectedTypePet + '/' + minmax.MinDate + '/' + minmax.MaxDate);
    }

    const nextWeek = () => {
        var minmax = new MinMaxDate();
        minmax.MinDate = selectedMinMax.MinDate + weekStepTS;
        minmax.MaxDate = selectedMinMax.MaxDate + weekStepTS;
        navigate('/planning/' + selectedTypePet + '/' + minmax.MinDate + '/' + minmax.MaxDate);
    }

    const previousMonth = () => {
        let yeartmp = selectedYear;
        let monthtmp = selectedMonth - 1;
        if (monthtmp < 1) {
            monthtmp = 12
            yeartmp = (selectedYear - 1);
        }
        let minmax = getMinMaxWithMonthYear(monthtmp, yeartmp);
        navigate('/planning/' + selectedTypePet + '/' + minmax.MinDate + '/' + minmax.MaxDate);
    }

    const nextMonth = () => {
        let yeartmp = selectedYear;
        let monthtmp = selectedMonth + 1;
        if (monthtmp > 12) {
            monthtmp = 1
            yeartmp = (selectedYear + 1);
        }
        let minmax = getMinMaxWithMonthYear(monthtmp, yeartmp);
        navigate('/planning/' + selectedTypePet + '/' + minmax.MinDate + '/' + minmax.MaxDate);
    }

    const onChangeMonth = (event: any) => {
        let minmax = getMinMaxWithMonthYear(parseInt(event.target.value), selectedYear);
        navigate('/planning/' + selectedTypePet + '/' + minmax.MinDate + '/' + minmax.MaxDate);
    }

    const onChangeYear = (event: any) => {
        let minmax = getMinMaxWithMonthYear(selectedMonth, parseInt(event.target.value));
        navigate('/planning/' + selectedTypePet + '/' + minmax.MinDate + '/' + minmax.MaxDate);
    }

    const onSelectedTypeChange = (event: any) => {
        setSelectedTypePet(Number.parseInt(event.target.value));
        navigate('/planning/' + parseInt(event.target.value) + '/' + selectedMinMax.MinDate + '/' + selectedMinMax.MaxDate);
    }

    const openEditPeriode = async (editPeriode: Periode) => {
        setModeVue("edit");
        setSelectedPeriode(editPeriode);
    }

    const openEditEvent = async (editPeriode: PlanningEvent) => {
        setModeVue("editEvent");
        setSelectedPlanningEvent(editPeriode);
    }

    const onCloseDlg = async () => {
        setModeVue("display");
    }

    const onUpdate = async () => {
        setModeVue("display");
        reloadData(selectedTypePet, selectedMinMax)

    }

    const deletePeriode = async (idperiode: number) => {
        dataService.Periodes.deletePeriode(idperiode).then((response) => { loadData(); });
        onCloseDlg();
    }

    const buildPlanning = async (listevt: PlanningEvent[], listPer: Periode[], listBox: Box[], selectMinMaxTmp: MinMaxDate) => {

        let dayInSecond = 60 * 60 * 24;
        let widthForOneDay = 0;

        //calcul du calendrier
        let listMonthTmp = [];
        let listDayTmp = [];
        let listWeekendTmp = [];

        let nbDay = Math.trunc((selectMinMaxTmp.MaxDate - selectMinMaxTmp.MinDate) / (dayInSecond)) + 1;
        if (nbDay > 31)
            nbDay = 31;

        // let widthForAllDay = ((refPlanning.current ? refPlanning.current['offsetWidth'] : 0) - 18) - widthColBox;
        let widthForAllDay = ((windowSize ? windowSize.innerWidth : 0) - 32 - 70) - widthColBox;
        widthForOneDay = Math.trunc(widthForAllDay / nbDay);
        if (widthForOneDay < 33)
            widthForOneDay = 33;
        widthForAllDay = widthForOneDay * nbDay;
        setWidthCalendar(widthForOneDay * nbDay + widthColBox);
        let tailleByUnit = widthForAllDay / (selectMinMaxTmp.MaxDate - selectMinMaxTmp.MinDate);
        let percentByUnit = 100 / (selectMinMaxTmp.MaxDate - selectMinMaxTmp.MinDate);

        setHeightScheduler(document.documentElement.clientHeight - 240);//this.planning.nativeElement.offsetHeight - 110;

        var posleft = widthColBox;
        var posleftPercent = 0;
        var dateInc = selectMinMaxTmp.MinDate;

        //calcul liste numéro de jours
        let currentDay = moment.unix(selectMinMaxTmp.MinDate);
        let listNumDay = [];
        for (var d = 0; d < nbDay; d++) {

            let date = new InfoDayCalendar();

            date.Jour = currentDay.date();
            date.Month = currentDay.month();
            date.Year = currentDay.year();
            listNumDay.push(date);
            currentDay.add(1, 'days')
        }


        for (var nd = 0; nd < nbDay; nd++) {
            var info = listNumDay[nd];
            info.Width = widthForOneDay;
            info.Height = 52;
            listDayTmp.push(info);

            //calcul mois Info
            let findMonthInfo = listMonthTmp.find(x => x.Month === info.Month + 1 && x.Year === info.Year);
            if (findMonthInfo === undefined) {
                let infoMonth = new InfoDayCalendar();
                if (info.Jour === 1)
                    infoMonth.Jour = 1;
                else
                    infoMonth.Jour = 0;
                infoMonth.Month = info.Month + 1;
                infoMonth.Year = info.Year;
                infoMonth.Posleft = posleft;
                infoMonth.LeftPercent = posleftPercent;
                infoMonth.Width = widthForOneDay;
                infoMonth.NbDay = 1;
                listMonthTmp.push(infoMonth);
            } else {
                findMonthInfo.Width += widthForOneDay;
                findMonthInfo.NbDay += 1;
            }



            var day = (new Date((dateInc + 3600 * 12) * 1000));
            if (day.getDay() === 0 || day.getDay() === 6) {
                var infowk = new InfoDayCalendar();
                infowk.Posleft = posleft - widthColBox;
                infowk.Width = widthForOneDay;
                infowk.ClassDay = 'timeline_weekend';
                info.ClassDay = 'timeline_weekend';
                listWeekendTmp.push(infowk);
            }
            // var options = { year: 'numeric', month: 'long', day: 'numeric' };

            if (new Date().toLocaleDateString('fr-fr', { year: 'numeric', month: 'long', day: 'numeric' }) === day.toLocaleDateString('fr-fr', { year: 'numeric', month: 'long', day: 'numeric' })) {
                var infotd = new InfoDayCalendar();
                infotd.Posleft = posleft - widthColBox;
                infotd.Width = widthForOneDay;
                infotd.ClassDay = 'timeline_today';
                listWeekendTmp.push(infotd);
            }

            posleft += widthForOneDay;
            posleftPercent += widthForOneDay * 100 / widthForAllDay;
            dateInc += (60 * 60 * 24);
        }

        //calcul des evenement
        let listEventtmp = [];
        var nbEventMaxInSpace = 0
        for (var i = 0; i < listevt.length; i++) {

            var eventInfo = listevt[i];

            let diffTs = eventInfo.DateFinPlan - eventInfo.DateDebPlan;

            eventInfo.Left = tailleByUnit * (eventInfo.DateDebPlan - selectMinMaxTmp.MinDate);//-(tailleByUnit*60*60);
            eventInfo.Width = tailleByUnit * (diffTs);
            eventInfo.LeftPercent = percentByUnit * (eventInfo.DateDebPlan - selectMinMaxTmp.MinDate);
            eventInfo.WidthPercent = percentByUnit * (diffTs);
            eventInfo.Top = 2;
            eventInfo.Line = 0;

            var lineNotEmpty = [];
            for (var k = 0; k < i; k++) {
                var eventPrevious = listEventtmp[k];
                if ((eventPrevious.Left <= eventInfo.Left && eventInfo.Left <= (eventPrevious.Left + eventPrevious.Width)) ||
                    (eventPrevious.Left <= (eventInfo.Left + eventInfo.Width) && (eventInfo.Left + eventInfo.Width) <= (eventPrevious.Left + eventPrevious.Width)) ||
                    (eventInfo.Left <= eventPrevious.Left && eventPrevious.Left <= (eventInfo.Left + eventInfo.Width)) ||
                    (eventInfo.Left <= (eventPrevious.Left + eventPrevious.Width) && (eventPrevious.Left + eventPrevious.Width) <= (eventInfo.Left + eventInfo.Width))
                ) {
                    //  console.log("ouu");
                    lineNotEmpty.push(eventPrevious.Line);
                }
                //console.log(lineNotEmpty);
            }

            lineNotEmpty.sort((a,b) => a-b);
            
            var myPos = -1;
            for (var l = 0; l < lineNotEmpty.length && myPos === -1; l++) {
                if (l !== lineNotEmpty[l]) {
                    myPos = l;
                }
            }
            if (myPos === -1)
                myPos = lineNotEmpty.length > 0 ? lineNotEmpty.length : 0;
            if (myPos > 0) {
                eventInfo.Top = 2 + 52 * myPos;
                eventInfo.Line = myPos;
            }

            if (myPos > nbEventMaxInSpace) {
                nbEventMaxInSpace = myPos;
            }
            listEventtmp.push(eventInfo);
        }

        if (nbEventMaxInSpace > 0)
            setHeightLineEvent(52 + nbEventMaxInSpace * 52);
        setListEvent(listEventtmp);


        //calcul des périodes/box
        let listBoxPeriodeTmp = [];
        for (var i = 0; i < listBox.length; i++) {
            var boxPeriode: BoxPeriode = new BoxPeriode();
            boxPeriode.BoxInfo = listBox[i];
            boxPeriode.ListPeriode = listPer.filter(x => x.IdBox === listBox[i].Id);
            boxPeriode.Height = 52;
            var nbPeriodeMaxInSpace = 0;
            for (var j = 0; j < boxPeriode.ListPeriode.length; j++) {
                var periode = boxPeriode.ListPeriode[j];


                let debPer = (moment.unix(periode.DateDebPlan));
                let finPer = (moment.unix(periode.DateFinPlan));

                for (debPer = debPer.startOf('day'); debPer <= finPer; debPer.add(1, 'days')) {
                    let dayfind = listDayTmp.find(x => x.Jour === debPer.date() && x.Month === debPer.month() && x.Year === debPer.year());
                    if (dayfind) {
                        dayfind.NbPet++;
                    }
                }



                let diffTs = periode.DateFinPlan - periode.DateDebPlan;

                periode.Left = tailleByUnit * (periode.DateDebPlan - selectMinMaxTmp.MinDate);//-(tailleByUnit*60*60);
                periode.Width = tailleByUnit * (diffTs);
                periode.LeftPercent = percentByUnit * (periode.DateDebPlan - selectMinMaxTmp.MinDate);
                periode.WidthPercent = percentByUnit * (diffTs);
                periode.Top = 2;
                periode.Line = 0;

                var lineNotEmpty = [];
                for (var k = 0; k < j; k++) {
                    var periodePrevious = boxPeriode.ListPeriode[k];
                    if ((periodePrevious.Left <= periode.Left && periode.Left <= (periodePrevious.Left + periodePrevious.Width)) ||
                        (periodePrevious.Left <= (periode.Left + periode.Width) && (periode.Left + periode.Width) <= (periodePrevious.Left + periodePrevious.Width)) ||
                        (periode.Left <= periodePrevious.Left && periodePrevious.Left <= (periode.Left + periode.Width)) ||
                        (periode.Left <= (periodePrevious.Left + periodePrevious.Width) && (periodePrevious.Left + periodePrevious.Width) <= (periode.Left + periode.Width))
                    ) {
                        if(lineNotEmpty.findIndex(x=>x===periodePrevious.Line)===-1)
                            lineNotEmpty.push(periodePrevious.Line);
                    }
                }
                
                lineNotEmpty.sort((a,b) => a-b);

                var myPos = -1;
                for (var l = 0; l < lineNotEmpty.length && myPos === -1; l++) {
                    if (l !== lineNotEmpty[l]) {
                        myPos = l;
                    }
                }


                if (myPos === -1)
                    myPos = lineNotEmpty.length > 0 ? lineNotEmpty.length : 0;

                 

                if (myPos > 0) {
                    periode.Top = 2 + 52 * myPos;
                    periode.Line = myPos;
                }
                
                if (myPos > nbPeriodeMaxInSpace) {
                    nbPeriodeMaxInSpace = myPos;
                }
               
                
               
                
            }
            if (nbPeriodeMaxInSpace > 0)
                boxPeriode.Height = 52 + nbPeriodeMaxInSpace * 52;


            listBoxPeriodeTmp.push(boxPeriode);

        }


        setListMonth(listMonthTmp);
        setListDay(listDayTmp);
        setListBoxPeriode(listBoxPeriodeTmp);
        setListWeekend(listWeekendTmp);
    }

    return (
        <div className="container-fluid margtop planning">
            {modeVue === "display" && <div>
                <div className="row" style={{ minWidth: '1250px' }}>
                    <div className="col">
                        <div className="d-flex justify-content-between">

                            <select className="form-control s250" onChange={(event) => onSelectedTypeChange(event)} value={selectedTypePet}>
                                {listTypePet && listTypePet.map((item) => (<option key={item.Id} value={item.Id} >{item.Libelle}</option>))}
                            </select>

                            <div className="d-flex">
                                <div className="d-flex">
                                    <div className="cursorPointer btn_change_date" onClick={previousMonth}> <i className="fa-solid fa-angle-double-left" /></div>
                                    <div className="cursorPointer btn_change_date" onClick={previousWeek}> <i className="fa-solid fa-angle-left" /></div>
                                </div>
                                <div >

                                    <select className="form-control" onChange={onChangeMonth} value={selectedMonth}>
                                        <option value="1">Janvier</option>
                                        <option value="2">Février</option>
                                        <option value="3">Mars</option>
                                        <option value="4">Avril</option>
                                        <option value="5">Mai</option>
                                        <option value="6">Juin</option>
                                        <option value="7">Juillet</option>
                                        <option value="8">Août</option>
                                        <option value="9">Septembre</option>
                                        <option value="10">Octobre</option>
                                        <option value="11">Novembre</option>
                                        <option value="12">Décembre</option>
                                    </select>
                                </div>
                                <div >
                                    <select className="form-control" value={selectedYear} onChange={onChangeYear} >
                                        {listYear && listYear.map((year) => (<option key={year} value={year}>{year}</option>))}
                                    </select>
                                </div>
                                <div className="d-flex">

                                    <div className="cursorPointer btn_change_date" onClick={nextWeek}> <i className="fa-solid fa-angle-right" /></div>
                                    <div className="cursorPointer btn_change_date" onClick={nextMonth}> <i className="fa-solid fa-angle-double-right" /></div>
                                </div>
                            </div>
                            <div >
                                <CreatePlanning typeAnimal={selectedTypePet} infoMinMaxDate={selectedMinMax} lisbox={listBox} listperiode={listPeriode} />
                            </div>
                        </div >
                    </div >
                </div >
                <div>
                    <div className="row">

                        <div className="col" id="divcontents" >
                            <div id="scheduler_here" style={{ width: widthCalendar + 'px', height: '100%', backgroundColor: 'white' }}>

                                <div id="pln_header" style={{ height: heightHeader +heightLineEvent+ 'px', width: widthCalendar + 'px' }}>
                                    <div style={{ display: 'flex' }}>
                                        <div className="divtopleft" style={{ maxWidth: widthColBox + 'px', minWidth: widthColBox + 'px' }}></div>
                                        <div style={{ display: 'flex', flexDirection: 'column' }} className="calendarDay">
                                            <div style={{ height: '30px', display: 'flex' }}>
                                                {listMonth && listMonth.map((infoMonth, index) => (
                                                    <div key={index} className={`monthInfo ${infoMonth.Jour === 1 ? 'firstDay' : ''}`}
                                                        style={{ lineHeight: '30px', height: '30px', top: '0px', width: infoMonth.Width + 'px', left: infoMonth.Posleft + 'px' }}>
                                                        {convertMonthToString(infoMonth.Month) + ' ' + infoMonth.Year}</div>
                                                ))}
                                            </div>

                                            <div style={{ display: 'flex' }}>

                                                {listDay && listDay.map((infoJour, index) => (
                                                    <div key={index}
                                                        className={`dayItem ${infoJour.Jour === 1 ? 'firstDay' : ''}`}
                                                        style={{ lineHeight: '18px', height: '40px', top: '0px', width: infoJour.Width + 'px' }}>
                                                        <div>{infoJour.Jour}</div>
                                                        <div>({infoJour.NbPet})</div>
                                                    </div>))}

                                            </div>

                                        </div>
                                    </div>
                                    <PlanningOther listEvent={listEvent} heightLineEvent={heightLineEvent} infoMinMaxDate={selectedMinMax} listDay={listDay} listWeekend={listWeekend} openEditEvent={openEditEvent} />
                              </div>

                                <div style={{ width: widthCalendar + 20 + 'px', height: heightScheduler + 'px', left: '0px', top: `'${heightLineEvent+61}+px'` }}
                                    id="pln_content" className="pln_content">
                      
                                    {/* pour chaque box  */}
                                    {listBoxPeriode && listBoxPeriode.map((boxPeriode) => (
                                        <PlanningLine key={boxPeriode.BoxInfo.Id} boxPeriode={boxPeriode} infoMinMaxDate={selectedMinMax} listDay={listDay} listWeekend={listWeekend} selectedType={selectedTypePet} openEditPeriode={openEditPeriode} />
                                    ))
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            {modeVue === "edit" && <PeriodeEditComposant onClose={onCloseDlg} onUpdate={onUpdate} periode={selectedPeriode} />}
            {modeVue === "editEvent" && <EventEditComposant onClose={onCloseDlg} onUpdate={onUpdate} planningEvent={selectedPlanningEvent} />}
        </div>
    );

}

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}